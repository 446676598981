// import Dropdown from "react-dropdown";
// import "react-dropdown/style.css";

// const Mapping = ({
//   setHasChanges,
//   mapping,
//   setMapping,
//   csvMapping,
//   setCsvMapping,
//   jsonMapping,
//   setJsonMapping,
//   xmlMapping,
//   setXmlMapping,
//   outputFields,
//   showNull,
//   setShowNull,
//   value,
//   setValue,
//   outputCsvFields,
//   outputJsonFields,
//   outputXmlFields,
//   isToggledForHeader,
//   fileExtractedData,
//   // setFileExtractedData,
//   // formatName,
//   // errorMessage
// }: {
//   setHasChanges: (value: boolean) => void;
//   mapping: { [key: string]: string };
//   setMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
//   csvMapping: { [key: string]: string };
//   setCsvMapping: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   jsonMapping: { [key: string]: string };
//   setJsonMapping: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   xmlMapping: { [key: string]: string };
//   setXmlMapping: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   showNull: string;
//   setShowNull: (value: string) => void;
//   value: string | null;
//   setValue: (value: string) => void;
//   outputFields: string[];
//   outputCsvFields: string[];
//   outputJsonFields: string[];
//   outputXmlFields: string[];
//   isToggledForHeader: boolean;
//   fileExtractedData: { [key: string]: any };
//   // setFileExtractedData: React.Dispatch<
//   //   React.SetStateAction<{ [key: string]: any }>
//   // >;
//   // formatName: string;
//   // errorMessage:string
// }) => {
//   const options = ["Yes", "No"];

//   const dropdownOptions = Array.isArray(fileExtractedData)
//     ? fileExtractedData.map((item: string) => ({
//         label: item,
//         value: item,
//       }))
//     : [];

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setShowNull(e.target.value);
//   };

//   const handleMappingChange = (
//     output: string,
//     input: string,
//     format: string
//   ) => {
//     const valueToSet = input || " ";
//     if (format === "all") {
//       setMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }
//     if (format === "csv") {
//       setCsvMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }
//     if (format === "json") {
//       setJsonMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }
//     if (format === "xml") {
//       setXmlMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }

//     setHasChanges(true);
//   };
//   return (
//     // <div className="p-2 overflow-auto ">
//     <div className="p-2">
//       <p className="text-center text-lg font-semibold">
//         Do you want to show the null fields?
//       </p>
//       <div className="flex flex-wrap justify-center">
//         {options.map((option) => (
//           <div key={option} className="text-sm font-medium p-2">
//             <label>
//               <input
//                 type="radio"
//                 name="optionGroup"
//                 value={option}
//                 checked={showNull === option}
//                 onChange={handleChange}
//                 className="mr-2"
//               />
//               {option.toUpperCase()}
//             </label>
//           </div>
//         ))}
//       </div>
//       {showNull === "Yes" && (
//         <div>
//           <p className="text-center mt-10">
//             Select the value needed to set for the alias
//           </p>
//           <div className="flex justify-center mb-4">
//             <input
//               type="text"
//               value={value ?? ""}
//               onChange={(e) => setValue(e.target.value)}
//               placeholder="Enter value"
//               className="border-2 border-gray-300 p-1 w-72 mt-2"
//             />
//           </div>
//         </div>
//       )}
//       {showNull !== "" && (
//         <>
//           <h2 className="text-2xl text-center font-semibold mb-4 mt-10">
//             Mapping Input to Output
//           </h2>
//           {isToggledForHeader ? (
//             outputFields.length > 0 && (
//               <div className="w-full">
//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-20">
//                   {outputFields.map((outputField) => (
//                     <div key={outputField} className="flex flex-col mb-4">
//                       <label
//                         htmlFor={outputField}
//                         className="text-gray-800 font-medium mb-2"
//                       >
//                         {outputField}
//                       </label>
//                       <Dropdown
//                         options={dropdownOptions}
//                         value={mapping[outputField] || ""}
//                         onChange={(e) =>
//                           handleMappingChange(outputField, e.value, "all")
//                         }
//                         placeholder="Select Input"
//                         className="rounded-lg  w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 "
//                         arrowClassName="mt-1"
//                       />
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )
//           ) : (
//             <div className="space-y-6">
//               {/* CSV Fields Mapping */}
//               {outputCsvFields.length > 0 && (
//                 <div className="w-full">
//                   <h3 className="font-bold mb-4">For CSV fields</h3>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-20">
//                     {outputCsvFields.map((outputField) => (
//                       <div key={outputField} className="flex flex-col mb-4">
//                         <label
//                           htmlFor={outputField}
//                           className="text-gray-800 font-medium mb-2"
//                         >
//                           {outputField}
//                         </label>
//                         <Dropdown
//                           options={dropdownOptions}
//                           value={csvMapping[outputField] || ""}
//                           onChange={(e) =>
//                             handleMappingChange(outputField, e.value, "csv")
//                           }
//                           placeholder="Select Input"
//                           className=" rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//               {/* JSON Fields Mapping */}
//               {outputJsonFields.length > 0 && (
//                 <div className="w-full">
//                   <h3 className="font-bold mb-4">For JSON fields</h3>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {outputJsonFields.map((outputField) => (
//                       <div key={outputField} className="flex flex-col mb-4">
//                         <label
//                           htmlFor={outputField}
//                           className="text-gray-800 font-medium mb-2"
//                         >
//                           {outputField}
//                         </label>
//                         <Dropdown
//                           options={dropdownOptions}
//                           value={jsonMapping[outputField] || ""}
//                           onChange={(e) =>
//                             handleMappingChange(outputField, e.value, "json")
//                           }
//                           placeholder="Select Input"
//                           className=" rounded-lg  w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//               {/* XML Fields Mapping */}
//               {outputXmlFields.length > 0 && (
//                 <div className="w-full">
//                   <h3 className="font-bold mb-4">For XML fields</h3>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {outputXmlFields.map((outputField) => (
//                       <div key={outputField} className="flex flex-col mb-4">
//                         <label
//                           htmlFor={outputField}
//                           className="text-gray-800 font-medium mb-2"
//                         >
//                           {outputField}
//                         </label>
//                         <Dropdown
//                           options={dropdownOptions}
//                           value={xmlMapping[outputField] || ""}
//                           onChange={(e) =>
//                             handleMappingChange(outputField, e.value, "xml")
//                           }
//                           placeholder="Select Input"
//                           className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default Mapping;

// import Select from "react-select";
// import "react-dropdown/style.css"; 

// const Mapping = ({
//   setHasChanges,
//   mapping,
//   setMapping,
//   csvMapping,
//   setCsvMapping,
//   jsonMapping,
//   setJsonMapping,
//   xmlMapping,
//   setXmlMapping,
//   outputFields,
//   showNull,
//   setShowNull,
//   value,
//   setValue,
//   outputCsvFields,
//   outputJsonFields,
//   outputXmlFields,
//   isToggledForHeader,
//   fileExtractedData,
// }: {
//   setHasChanges: (value: boolean) => void;
//   mapping: { [key: string]: string };
//   setMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
//   csvMapping: { [key: string]: string };
//   setCsvMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
//   jsonMapping: { [key: string]: string };
//   setJsonMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
//   xmlMapping: { [key: string]: string };
//   setXmlMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
//   showNull: string;
//   setShowNull: (value: string) => void;
//   value: string | null;
//   setValue: (value: string) => void;
//   outputFields: string[];
//   outputCsvFields: string[];
//   outputJsonFields: string[];
//   outputXmlFields: string[];
//   isToggledForHeader: boolean;
//   fileExtractedData: { [key: string]: any };
// }) => {
//   const options = ["Yes", "No"];

//   const dropdownOptions = Array.isArray(fileExtractedData)
//     ? fileExtractedData.map((item: string) => ({
//         label: item,
//         value: item,
//       }))
//     : [];
// console.log("dropdownOptions",dropdownOptions)
//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setShowNull(e.target.value);
//   };

//   const handleMappingChange = (
//     output: string,
//     input: string,
//     format: string
//   ) => {
//     const valueToSet = input || " ";
//     if (format === "all") {
//       setMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }
//     if (format === "csv") {
//       setCsvMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }
//     if (format === "json") {
//       setJsonMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }
//     if (format === "xml") {
//       setXmlMapping((prev) => ({
//         ...prev,
//         [output]: valueToSet,
//       }));
//     }

//     setHasChanges(true);
//   };

//   return (
//     <div className="p-2">
//       <p className="text-center text-lg font-semibold">
//         Do you want to show the null fields?
//       </p>
//       <div className="flex flex-wrap justify-center">
//         {options.map((option) => (
//           <div key={option} className="text-sm font-medium p-2">
//             <label>
//               <input
//                 type="radio"
//                 name="optionGroup"
//                 value={option}
//                 checked={showNull === option}
//                 onChange={handleChange}
//                 className="mr-2"
//               />
//               {option.toUpperCase()}
//             </label>
//           </div>
//         ))}
//       </div>
//       {showNull === "Yes" && (
//         <div>
//           <p className="text-center mt-10">
//             Select the value needed to set for the alias
//           </p>
//           <div className="flex justify-center mb-4">
//             <input
//               type="text"
//               value={value ?? ""}
//               onChange={(e) => setValue(e.target.value)}
//               placeholder="Enter value"
//               className="border-2 border-gray-300 p-1 w-72 mt-2"
//             />
//           </div>
//         </div>
//       )}
//       {showNull !== "" && (
//         <>
//           <h2 className="text-2xl text-center font-semibold mb-4 mt-10">
//             Mapping Input to Output
//           </h2>
//           {isToggledForHeader ? (
//             outputFields.length > 0 && (
//               <div className="w-full">
//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-20">
//                   {outputFields.map((outputField) => (
//                     <div key={outputField} className="flex flex-col mb-4">
//                       <label
//                         htmlFor={outputField}
//                         className="text-gray-800 font-medium mb-2"
//                       >
//                         {outputField}
//                       </label>
//                       <Select
//                         options={dropdownOptions}
//                         value={dropdownOptions.find(
//                           (option) => option.value === mapping[outputField]
//                         )}
//                         onChange={(e: any) =>
//                           handleMappingChange(outputField, e.value, "all")
//                         }
//                         placeholder="Select Input"
//                         className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                         isSearchable
//                       />
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )
//           ) : (
//             <div className="space-y-6">
//               {/* CSV Fields Mapping */}
//               {outputCsvFields.length > 0 && (
//                 <div className="w-full">
//                   <h3 className="font-bold mb-4">For CSV fields</h3>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-20">
//                     {outputCsvFields.map((outputField) => (
//                       <div key={outputField} className="flex flex-col mb-4">
//                         <label
//                           htmlFor={outputField}
//                           className="text-gray-800 font-medium mb-2"
//                         >
//                           {outputField}
//                         </label>
//                         <Select
//                           options={dropdownOptions}
//                           value={dropdownOptions.find(
//                             (option) => option.value === csvMapping[outputField]
//                           )}
//                           onChange={(e: any) =>
//                             handleMappingChange(outputField, e.value, "csv")
//                           }
//                           placeholder="Select Input"
//                           className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                           isSearchable
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//               {/* JSON Fields Mapping */}
//               {outputJsonFields.length > 0 && (
//                 <div className="w-full">
//                   <h3 className="font-bold mb-4">For JSON fields</h3>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {outputJsonFields.map((outputField) => (
//                       <div key={outputField} className="flex flex-col mb-4">
//                         <label
//                           htmlFor={outputField}
//                           className="text-gray-800 font-medium mb-2"
//                         >
//                           {outputField}
//                         </label>
//                         <Select
//                           options={dropdownOptions}
//                           value={dropdownOptions.find(
//                             (option) => option.value === jsonMapping[outputField]
//                           )}
//                           onChange={(e: any) =>
//                             handleMappingChange(outputField, e.value, "json")
//                           }
//                           placeholder="Select Input"
//                           className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                           isSearchable
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//               {/* XML Fields Mapping */}
//               {outputXmlFields.length > 0 && (
//                 <div className="w-full">
//                   <h3 className="font-bold mb-4">For XML fields</h3>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {outputXmlFields.map((outputField) => (
//                       <div key={outputField} className="flex flex-col mb-4">
//                         <label
//                           htmlFor={outputField}
//                           className="text-gray-800 font-medium mb-2"
//                         >
//                           {outputField}
//                         </label>
//                         <Select
//                           options={dropdownOptions}
//                           value={dropdownOptions.find(
//                             (option) => option.value === xmlMapping[outputField]
//                           )}
//                           onChange={(e: any) =>
//                             handleMappingChange(outputField, e.value, "xml")
//                           }
//                           placeholder="Select Input"
//                           className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
//                           isSearchable
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default Mapping;



import Select from "react-select";
import "react-dropdown/style.css";

const Mapping = ({
  setHasChanges,
  mapping,
  setMapping,
  csvMapping,
  setCsvMapping,
  jsonMapping,
  setJsonMapping,
  xmlMapping,
  setXmlMapping,
  outputFields,
  showNull,
  setShowNull,
  value,
  setValue,
  outputCsvFields,
  outputJsonFields,
  outputXmlFields,
  isToggledForHeader,
  fileExtractedData,
}: {
  setHasChanges: (value: boolean) => void;
  mapping: { [key: string]: string };
  setMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  csvMapping: { [key: string]: string };
  setCsvMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  jsonMapping: { [key: string]: string };
  setJsonMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  xmlMapping: { [key: string]: string };
  setXmlMapping: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  showNull: string;
  setShowNull: (value: string) => void;
  value: string | null;
  setValue: (value: string) => void;
  outputFields: string[];
  outputCsvFields: string[];
  outputJsonFields: string[];
  outputXmlFields: string[];
  isToggledForHeader: boolean;
  fileExtractedData: { [key: string]: any };
}) => {
  const options = ["Yes", "No"];

  // Prepare dropdown options only once
  const dropdownOptions = Array.isArray(fileExtractedData)
    ? fileExtractedData.map((item: string) => ({
        label: item,
        value: item,
      }))
    : [];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowNull(e.target.value);
  };

  // Generalized mapping change handler
  const handleMappingChange = (
    output: string,
    input: string,
    format: string
  ) => {
    const valueToSet = input || " ";
    const updateMapping = {
      all: setMapping,
      csv: setCsvMapping,
      json: setJsonMapping,
      xml: setXmlMapping,
    }[format];

    if (updateMapping) {
      updateMapping((prev) => ({
        ...prev,
        [output]: valueToSet,
      }));
    }

    setHasChanges(true);
  };

  const renderDropdowns = (fields: string[], mappingObject: { [key: string]: string }, format: string) => (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-20">
        {fields.map((outputField) => (
          <div key={outputField} className="flex flex-col mb-4">
            <label htmlFor={outputField} className="text-gray-800 font-medium mb-2">
              {outputField}
            </label>
            <Select
              options={dropdownOptions}
              value={dropdownOptions.find((option) => option.value === mappingObject[outputField])}
              onChange={(e: any) => handleMappingChange(outputField, e.value, format)}
              placeholder="Select Input"
              className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
              isSearchable
            />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="p-2">
      <p className="text-center text-lg font-semibold">Do you want to show the null fields?</p>
      <div className="flex flex-wrap justify-center">
        {options.map((option) => (
          <div key={option} className="text-sm font-medium p-2">
            <label>
              <input
                type="radio"
                name="optionGroup"
                value={option}
                checked={showNull === option}
                onChange={handleChange}
                className="mr-2"
              />
              {option.toUpperCase()}
            </label>
          </div>
        ))}
      </div>
      {showNull === "Yes" && (
        <div>
          <p className="text-center mt-10">Select the value needed to set for the alias</p>
          <div className="flex justify-center mb-4">
            <input
              type="text"
              value={value ?? ""}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter value"
              className="border-2 border-gray-300 p-1 w-72 mt-2"
            />
          </div>
        </div>
      )}
      {showNull !== "" && (
        <>
          <h2 className="text-2xl text-center font-semibold mb-4 mt-10">Mapping Input to Output</h2>
          {isToggledForHeader ? (
            outputFields.length > 0 && renderDropdowns(outputFields, mapping, "all")
          ) : (
            <div className="space-y-6">
              {outputCsvFields.length > 0 && (
                <div>
                  <h3 className="font-bold mb-4">For CSV fields</h3>
                  {renderDropdowns(outputCsvFields, csvMapping, "csv")}
                </div>
              )}
              {outputJsonFields.length > 0 && (
                <div>
                  <h3 className="font-bold mb-4">For JSON fields</h3>
                  {renderDropdowns(outputJsonFields, jsonMapping, "json")}
                </div>
              )}
              {outputXmlFields.length > 0 && (
                <div>
                  <h3 className="font-bold mb-4">For XML fields</h3>
                  {renderDropdowns(outputXmlFields, xmlMapping, "xml")}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Mapping;

