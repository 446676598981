// import { useState, useEffect } from "react";
// import Modal from "./../../components/Modal";
// import { Download } from "../../components/Download";
// import axios from "axios";
// import ViewCsv from "../supportedFormats/csv/ViewCsv";
// import ViewXml from "../supportedFormats/xml/ViewXml";
// import ViewJson from "../supportedFormats/json/ViewJson";
// import {
//   DOWNLOAD_CSV,
//   DOWNLOAD_JSON,
//   DOWNLOAD_XML,
// } from "../../utils/api-details/ApiList";
// import pdf from "./../../assets/pdf-icon.png";
// import { IconDownload } from "@tabler/icons-react";
// import { useToast } from "../../components/Toast";

// export const ConfirmationPopup = ({
//   isConvertToJson,
//   isConvertTocsv,
//   onClose,
//   uploadedFileName,
//   isConvertToXml,
//   format_name,
//   setFileName,
//   setIsConvertToJson,
//   setIsConvertTocsv,
//   setIsConvertToXml
// }: {
//   isConvertToJson?: boolean;
//   isConvertTocsv?: boolean;
//   onClose: () => void;
//   uploadedFileName?: string[];
//   isConvertToXml?: boolean;
//   format_name?: string;
//   setFileName: React.Dispatch<React.SetStateAction<any[]>>;
//   setIsConvertToJson: React.Dispatch<React.SetStateAction<boolean>>;
//   setIsConvertTocsv:  React.Dispatch<React.SetStateAction<boolean>>;
//   setIsConvertToXml:  React.Dispatch<React.SetStateAction<boolean>>;
// }) => {
//   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
//   const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
//   const [isCsvViewModalOpen, setIsCsvViewModalOpen] = useState(false);
//   const [isXmlView, setIsXmlView] = useState(false);
//   const [selectedFile, setSelectedFile] = useState("");
//   const orgID = sessionStorage.getItem("orgId");
//   const userID = sessionStorage.getItem("userId");
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const download_json = DOWNLOAD_JSON;
//   const download_csv = DOWNLOAD_CSV;
//   const download_xml = DOWNLOAD_XML;
//   // const [isView, setIsView] = useState(false);
//   const { showToast } = useToast();

//   useEffect(() => {
//     console.log("isConvertToJson", isConvertToJson);
//     console.log("isConvertTocsv", isConvertTocsv);
//     console.log("isConvertToXml", isConvertToXml);
//     if (isConvertToJson || isConvertTocsv || isConvertToXml) {
//       setIsModalOpen(true);
//     }
//     //  else {
//     //   setIsModalOpen(false);
//     // }
//   }, [isConvertToJson, isConvertToXml, isConvertTocsv]);

//   const handleView = (fileName: string) => {
//     setSelectedFile(fileName);
//     console.log("fileName", fileName);
//     // setIsView(true)
//     if (isConvertToJson) {
//       setIsViewModalOpen(true);
//       // setIsModalOpen(false);
//     } else if (isConvertTocsv) {
//       setIsCsvViewModalOpen(true);
//       // setIsModalOpen(false);
//     } else if (isConvertToXml) {
//       setIsXmlView(true);
//       // setIsModalOpen(false);
//     }
//   };

//   const handleClosePopup = () => {
//     // setIsViewModalOpen(false);
//     // setIsCsvViewModalOpen(false);
//     // setIsModalOpen(false);
//     // setFileName([]);
//     //  setIsConvertToJson(false);
//     // setIsXmlView(false);
//     if (onClose) onClose();
//   };

//   const handleDownloadFile = async (filename: string) => {
//     console.log("filename", filename);
//     // const name = filename;
//     const name = filename.replace("_mapped", "");
//     console.log("name", name);
//     if (isConvertToJson) {
//       const fileName = name?.split(".")[0] + ".json";
//       await axios
//         .get(`${apiUrl + download_json}`, {
//           params: {
//             filename: fileName,
//             format_name: format_name,
//             version: 0,
//             type: "appended",
//             orgid: orgID,
//             user_id: userID,
//           },
//         })
//         .then((response) => {
//           Download(response.data, fileName);
//         })
//         .catch(function (error) {
//           console.log(error);
//           console.log("error", error?.response?.data?.detail);
//           const errorMsg = error?.response?.data?.detail;
//           showToast(errorMsg ? errorMsg : "Something went wrong!", "error");
//         });
//     }else if (isConvertTocsv) {
//       const FileName = name?.split(".")[0] + ".csv";
//       axios
//         .get(`${apiUrl + download_csv}`, {
//           params: {
//             filename: FileName,
//             format_name: format_name,
//             version: 0,
//             type: "appended",
//             orgid: orgID,
//             user_id: userID,
//           },
//           responseType: "blob",
//         })
//         .then((response) => {
//           const contentDisposition = response.headers["content-disposition"];
//           const filename = contentDisposition
//             ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
//             : FileName;
//           const url = window.URL.createObjectURL(new Blob([response.data]));
//           const a = document.createElement("a");
//           a.href = url;
//           a.download = filename;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//           window.URL.revokeObjectURL(url);
//         })
//         .catch((error) => {
//           console.error("Error downloading CSV file:", error);
//           console.log("error", error?.response?.data?.detail);
//           const errorMsg = error?.response?.data?.detail;
//           showToast(errorMsg ? errorMsg : "Error downloading CSV file!", "error");
//         });
//     } else if (isConvertToXml) {
//       const fileName = name?.split(".")[0] + ".xml";
//       axios
//         .get(`${apiUrl + download_xml}`, {
//           params: {
//             filename: fileName,
//             format_name: format_name,
//             version: 0,
//             type: "appended",
//             orgid: orgID,
//             user_id: userID,
//           },
//           responseType: "blob",
//         })
//         .then((response) => {
//           if (response.headers["content-type"] === "application/xml") {
//             const file = new Blob([response.data], { type: "application/xml" });
//             const fileURL = URL.createObjectURL(file);
//             const link = document.createElement("a");
//             link.href = fileURL;
//             link.download = response.headers["content-disposition"]
//               ? response.headers["content-disposition"]
//                   .split("filename=")[1]
//                   .replace(/['"]/g, "")
//               : fileName;
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             URL.revokeObjectURL(fileURL);
//           } else {
//             console.error(
//               "Unexpected content type:",
//               response.headers["content-type"]
//             );
//           }
//         })
//         .catch((error) => {
//           console.error("Error downloading XML file:", error);
//           console.log("error", error?.response?.data?.detail);
//           const errorMsg = error?.response?.data?.detail;
//           showToast(errorMsg ? errorMsg : "Error downloading XML file!", "error");
//         });
//     }
    

//     //  else if (isConvertTocsv) {
//     //   const FileName = name?.split(".")[0] + ".csv";
//     //   const response = await axios.get(`${apiUrl + download_csv}`, {
//     //     params: {
//     //       filename: FileName,
//     //       format_name: format_name,
//     //       version: 0,
//     //       type: "appended",
//     //       orgid: orgID,
//     //       user_id: userID,
//     //     },
//     //     responseType: "blob",
//     //   });
//     //   const contentDisposition = response.headers["content-disposition"];
//     //   const filename = contentDisposition
//     //     ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
//     //     : FileName;
//     //   const url = window.URL.createObjectURL(new Blob([response.data]));
//     //   const a = document.createElement("a");
//     //   a.href = url;
//     //   a.download = filename;
//     //   document.body.appendChild(a);
//     //   a.click();
//     //   document.body.removeChild(a);
//     //   window.URL.revokeObjectURL(url);
//     // } else if (isConvertToXml) {
//     //   const fileName = name?.split(".")[0] + ".xml";
//     //   const response = await axios.get(`${apiUrl + download_xml}`, {
//     //     params: {
//     //       filename: fileName,
//     //       format_name: format_name,
//     //       version: 0,
//     //       type: "appended",
//     //       orgid: orgID,
//     //       user_id: userID,
//     //     },
//     //     responseType: "blob",
//     //   });
//     //   if (response.headers["content-type"] === "application/xml") {
//     //     const file = new Blob([response.data], { type: "application/xml" });
//     //     const fileURL = URL.createObjectURL(file);
//     //     const link = document.createElement("a");
//     //     link.href = fileURL;
//     //     link.download = response.headers["content-disposition"]
//     //       ? response.headers["content-disposition"]
//     //           .split("filename=")[1]
//     //           .replace(/['"]/g, "")
//     //       : fileName;
//     //     document.body.appendChild(link);
//     //     link.click();
//     //     document.body.removeChild(link);
//     //     URL.revokeObjectURL(fileURL);
//     //   } else {
//     //     console.error(
//     //       "Unexpected content type:",
//     //       response.headers["content-type"]
//     //     );
//     //   }
//     // }
//   };

//   return (
//     <div>
//       <div className="p-6">
//         <Modal
//           isOpen={isModalOpen}
//           onClose={() => {
//             setIsModalOpen(false);
//             setFileName([]);
//             setIsConvertToJson(false);
//             setIsConvertTocsv(false);
//             setIsConvertToXml(false)
//             if (onClose) onClose();
//           }}
//           title=" "
//           showCancelButton={false}
//           size="w-5/12"
//         >
//           <div className=" items-center justify-between my-4">
//             {isConvertToJson && (
//               <p className="text-center text-lg">
//                 File is successfully converted into JSON
//               </p>
//             )}
//             {isConvertTocsv && (
//               <p className="text-center text-lg">
//                 File is successfully converted into CSV
//               </p>
//             )}
//             {isConvertToXml && (
//               <p className="text-center text-lg">
//                 File is successfully converted into XML
//               </p>
//             )}

//             {uploadedFileName?.length === 1 ? (
//               <div className="flex flex-col items-center mt-8">
//                 <div className="flex space-x-4">
//                   <button
//                     type="button"
//                     className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
//                     onClick={() => handleDownloadFile(uploadedFileName[0])}
//                   >
//                     Download
//                   </button>
//                   <button
//                     type="button"
//                     className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
//                     onClick={() => handleView(uploadedFileName[0])}
//                   >
//                     View
//                   </button>
//                 </div>
//               </div>
//             ) : (
//               <div
//                 className="mt-6"
//                 style={{
//                   scrollbarWidth: "thin",
//                   scrollbarColor: "#3b82f6 #f1f1f1",
//                   overflowX: "auto",
//                   WebkitOverflowScrolling: "touch",
//                   whiteSpace: "nowrap",
//                 }}
//               >
//                 <div>
//                   {uploadedFileName?.map((file, index) => (
//                     <div
//                       key={index}
//                       className="flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
//                     >
//                       <div className="flex items-center space-x-4 flex-grow">
//                         <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
//                         <div className="ml-4 flex flex-col">
//                           <p className="text-gray-700">{file}</p>
//                         </div>
//                       </div>
//                       <div className="ml-auto flex space-x-4 items-center">
//                         <button
//                           type="button"
//                           className="px-4 py-2 w-20 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
//                           onClick={() => handleView(file)}
//                           aria-label={`View ${file}`}
//                         >
//                           View
//                         </button>
//                         <IconDownload
//                           stroke={2}
//                           color="grey"
//                           onClick={() => handleDownloadFile(file)}
//                         />
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </Modal>
//       </div>

//       <ViewJson
//         isViewModalOpen={isViewModalOpen}
//         onClose={handleClosePopup}
//         uploadedFileName={selectedFile}
//         format_name={format_name}
//       />

//       <ViewCsv
//         isCsvViewModalOpen={isCsvViewModalOpen}
//         onClose={handleClosePopup}
//         uploadedFileName={selectedFile}
//         format_name={format_name}
//       />

//       <ViewXml
//         isXmlView={isXmlView}
//         onClose={handleClosePopup}
//         uploadedFileName={selectedFile}
//         format_name={format_name}
//       />
//     </div>
//   );
// };

// export default ConfirmationPopup;

import { useState, useEffect } from "react";
import Modal from "./../../components/Modal";
import { Download } from "../../components/Download";
import axios from "axios";
import ViewCsv from "../supportedFormats/csv/ViewCsv";
import ViewXml from "../supportedFormats/xml/ViewXml";
import ViewJson from "../supportedFormats/json/ViewJson";
import { DOWNLOAD_CSV, DOWNLOAD_JSON, DOWNLOAD_XML } from "../../utils/api-details/ApiList";
import pdf from "./../../assets/pdf-icon.png";
import { IconDownload } from "@tabler/icons-react";
import { useToast } from "../../components/Toast";

export const ConfirmationPopup = ({
  isConvertToJson,
  isConvertTocsv,
  onClose,
  uploadedFileName,
  isConvertToXml,
  format_name,
  setFileName,
  setIsConvertToJson,
  setIsConvertTocsv,
  setIsConvertToXml,
  setUploadInvoiceFiles,
  setSelectedOption
}: {
  isConvertToJson?: boolean;
  isConvertTocsv?: boolean;
  onClose: () => void;
  uploadedFileName?: string[];
  isConvertToXml?: boolean;
  format_name?: string;
  setFileName: React.Dispatch<React.SetStateAction<any[]>>;
  setIsConvertToJson: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConvertTocsv: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConvertToXml: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadInvoiceFiles: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedOption: (value: string) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isCsvViewModalOpen, setIsCsvViewModalOpen] = useState(false);
  const [isXmlView, setIsXmlView] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_URL;
  const download_json = DOWNLOAD_JSON;
  const download_csv = DOWNLOAD_CSV;
  const download_xml = DOWNLOAD_XML;
  const { showToast } = useToast();

  useEffect(() => {
    if (isConvertToJson || isConvertTocsv || isConvertToXml) {
      setIsModalOpen(true);
    }
  }, [isConvertToJson, isConvertTocsv, isConvertToXml]);

  const handleView = (fileName: string) => {
    setSelectedFile("")
    console.log("Viewww",fileName)
    console.log("isConvertToJson",isConvertToJson)
    console.log("isVViewModalOpen",isViewModalOpen)
    const name = fileName.replace("_mapped", "");

    setSelectedFile(name);
    if (isConvertToJson) {
      setIsViewModalOpen(true);
      setIsCsvViewModalOpen(false)
      setIsXmlView(false)
    } else if (isConvertTocsv) {
      setIsCsvViewModalOpen(true);
      setIsViewModalOpen(false);
      setIsXmlView(false)
    } else if (isConvertToXml) {
      setIsXmlView(true);
      setIsCsvViewModalOpen(false);
      setIsViewModalOpen(false);
    }
  };

  const handleClosePopup = () => {
    // setIsModalOpen(false);
    // setFileName([]);
    // setIsConvertToJson(false);
    // setIsConvertTocsv(false);
    // setIsConvertToXml(false);
    if (onClose) onClose();
  };

  const handleCloseConvertedInfo = () => {
    setIsModalOpen(false);
    setFileName([]);
    setIsConvertToJson(false);
    setIsConvertTocsv(false);
    setIsConvertToXml(false);
    setUploadInvoiceFiles([])
    setSelectedOption("");
    if (onClose) onClose();
  };

  const handleDownloadFile = (filename: string) => {
    const name = filename.replace("_mapped", "");
    let fileName = name.split(".")[0];
  
    // Handle JSON download
    if (isConvertToJson) {
      fileName += ".json";
      axios
        .get(`${apiUrl + download_json}`, {
          params: {
            filename: fileName,
            format_name: format_name,
            version: 0,
            type: "appended",
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          Download(response.data, fileName);
        })
        .catch((error) => {
          console.error("Error downloading JSON file:", error);
          const errorMsg = error?.response?.data?.detail || "Something went wrong during JSON file download!";
          showToast(errorMsg, "error");
        });
    }
  
    // Handle CSV download
    else if (isConvertTocsv) {
      fileName += ".csv";
      axios
        .get(`${apiUrl + download_csv}`, {
          params: {
            filename: fileName,
            format_name: format_name,
            version: 0,
            type: "appended",
            orgid: orgID,
            user_id: userID,
          },
          responseType: "blob",
        })
        .then((response) => {
          const contentDisposition = response.headers["content-disposition"];
          const filenameToDownload = contentDisposition
            ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
            : fileName;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = filenameToDownload;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading CSV file:", error);
          const errorMsg = error?.response?.data?.detail || "Error downloading CSV file!";
          showToast(errorMsg, "error");
        });
    }
  
    // Handle XML download
    else if (isConvertToXml) {
      fileName += ".xml";
      axios
        .get(`${apiUrl + download_xml}`, {
          params: {
            filename: fileName,
            format_name: format_name,
            version: 0,
            type: "appended",
            orgid: orgID,
            user_id: userID,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.headers["content-type"] === "application/xml") {
            const file = new Blob([response.data], { type: "application/xml" });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = response.headers["content-disposition"]
              ? response.headers["content-disposition"]
                  .split("filename=")[1]
                  .replace(/['"]/g, "")
              : fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
          } else {
            throw new Error("Unexpected content type: " + response.headers["content-type"]);
          }
        })
        .catch((error) => {
          console.error("Error downloading XML file:", error);
          const errorMsg = error?.response?.data?.detail || "Error downloading XML file!";
          showToast(errorMsg, "error");
        });
    }
  };
  
  return (
    <div>
      <div className="p-6">
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseConvertedInfo}
          title=" "
          showCancelButton={false}
          size="w-5/12"
        >
          <div className="items-center justify-between my-4">
            {isConvertToJson && <p className="text-center text-lg">File is successfully converted into JSON</p>}
            {isConvertTocsv && <p className="text-center text-lg">File is successfully converted into CSV</p>}
            {isConvertToXml && <p className="text-center text-lg">File is successfully converted into XML</p>}

            {uploadedFileName?.length === 1 ? (
              <div className="flex flex-col items-center mt-8">
                <div className="flex space-x-4">
                  <button
                    type="button"
                    className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                    onClick={() => handleDownloadFile(uploadedFileName[0])}
                  >
                    Download
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                    onClick={() => handleView(uploadedFileName[0])}
                  >
                    View
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="mt-6"
                style={{
                  scrollbarWidth: "thin",
                  scrollbarColor: "#3b82f6 #f1f1f1",
                  overflowX: "auto",
                  WebkitOverflowScrolling: "touch",
                  whiteSpace: "nowrap",
                }}
              >
                <div>
                  {uploadedFileName?.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
                    >
                      <div className="flex items-center space-x-4 flex-grow">
                        <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
                        <div className="ml-4 flex flex-col">
                          <p className="text-gray-700">{file}</p>
                        </div>
                      </div>
                      <div className="ml-auto flex space-x-4 items-center">
                        <button
                          type="button"
                          className="px-4 py-2 w-20 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                          onClick={() => handleView(file)}
                          aria-label={`View ${file}`}
                        >
                          View
                        </button>
                        <IconDownload
                          stroke={2}
                          color="grey"
                          onClick={() => handleDownloadFile(file)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>

      <ViewJson
        isViewModalOpen={isViewModalOpen}
        onClose={handleClosePopup}
        uploadedFileName={selectedFile}
        format_name={format_name}
        setIsViewModalOpen= {setIsViewModalOpen}
      />

      <ViewCsv
        isCsvViewModalOpen={isCsvViewModalOpen}
        onClose={handleClosePopup}
        uploadedFileName={selectedFile}
        format_name={format_name}
        setIsCsvViewModalOpen={setIsCsvViewModalOpen}
      />

      <ViewXml
        isXmlView={isXmlView}
        onClose={handleClosePopup}
        uploadedFileName={selectedFile}
        format_name={format_name}
        setIsXmlView= {setIsXmlView}
      />
    </div>
  );
};

export default ConfirmationPopup;
