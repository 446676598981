import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";

interface FormData {
  json_type: string;
  json_url: string;
  json_method?: string;
  json_requestType?: string;
  json_requestBody?: string;
  json_pathParam?: string;
  json_file?: FileList;

  csv_type: string;
  csv_url: string;
  csv_method?: string;
  csv_requestType?: string;
  csv_requestBody?: string;
  csv_pathParam?: string;
  csv_file?: FileList;

  xml_type: string;
  xml_url: string;
  xml_method?: string;
  xml_requestType?: string;
  xml_requestBody?: string;
  xml_pathParam?: string;
  xml_file?: FileList;

  format: string;
  selectedFormats: string[];
}

interface CsvFormProps {
  register: UseFormRegister<FormData>;
  errors: any;
}

const XmlForm: React.FC<CsvFormProps> = ({ register, errors }) => {
  return (
    <div className="w-full p-6 border-2 border-gray-300 rounded-lg bg-grey-50 pb-10">
      <h2 className="text-lg font-medium mb-4">For XML</h2>
      {/* Type Field */}
      <div>
        <label htmlFor="type" className="block text-gray-700 font-medium mb-2">
          Type
        </label>
        <input
          id="type"
          type="text"
          placeholder="Enter Type"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_type")}
        />
      </div>

      {/* URL Field */}
      <div>
        <label htmlFor="url" className="block text-gray-700 font-medium mb-2">
          URL
        </label>
        <input
          id="url"
          type="text"
          placeholder="Enter URL"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_url", { required: "URL is required" })}
        />
        {/* {errors.url && (
                        <span className="text-red-500">
                          {errors.url.message}
                        </span>
                      )} */}
      </div>

      {/* Method Field */}
      <div>
        <label
          htmlFor="method"
          className="block text-gray-700 font-medium mb-2"
        >
          Method
        </label>
        <input
          id="method"
          type="text"
          placeholder="Enter Methods (GET, POST, PATCH, PUT...)"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_method")}
        />
        {/* {errors.method && (
                        <span className="text-red-500">
                          {errors.method.message}
                        </span>
                      )} */}
      </div>

      {/* Request Type Field */}
      <div>
        <label
          htmlFor="requestType"
          className="block text-gray-700 font-medium mb-2"
        >
          Request Type
        </label>
        <input
          id="requestType"
          type="text"
          placeholder="Enter Request Type"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_requestType")}
        />
      </div>

      {/* Request Body Field */}
      <div>
        <label
          htmlFor="requestBody"
          className="block text-gray-700 font-medium mb-2"
        >
          Request Body
        </label>
        <input
          id="requestBody"
          type="text"
          placeholder="Enter Request body {}"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_requestBody")}
        />
        {/* {errors.requestBody && (
                        <span className="text-red-500">
                          {errors.requestBody.message}
                        </span>
                      )} */}
      </div>

      {/* Path Param Field */}
      <div>
        <label
          htmlFor="pathParam"
          className="block text-gray-700 font-medium mb-2"
        >
          Path Param
        </label>
        <input
          id="pathParam"
          type="text"
          placeholder="Enter Path Param"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_pathParam")}
        />
        {/* {errors.pathParam && (
                        <span className="text-red-500">
                          {errors.pathParam.message}
                        </span>
                      )} */}
      </div>

      {/* File Attachment Field */}
      <div>
        <label htmlFor="file" className="block text-gray-700 font-medium mb-2">
          Attach File
        </label>
        <input
          id="file"
          type="file"
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          {...register("xml_file")}
        />
      </div>
    </div>
  );
};

export default XmlForm;
