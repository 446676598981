// import Dropdown from "react-dropdown";
// import "react-dropdown/style.css";

// const label = [
//   { label: "Invoice No", value: "HFINVOICE_NO" },
//   { label: "Invoice Date", value: "HFINVOICE_DATE" },
//   { label: "Due Date", value: "HFDUEDATE" },
//   { label: "Total Amount", value: "HFTOTAL_AMOUNT" },
//   { label: "Total Gst", value: "HFTAX_AMOUNT" },
//   { label: "Payable To", value: "HFVENDOR_NAME" },
//   { label: "Invoice Currency", value: "HFTRADE_CURRENCY" },
//   { label: "Buyer Name", value: "HFBUYER_NAME" },
//   { label: "Commodity Data", value: "FCOMMODITY_DATA" },
//   { label: "Taxes Table", value: "FTAX_DATA" },
//   { label: "Charges Table", value: "FCHARGES_DATA" },
// ];

// const FinalConiguration = ({
//   //   fileExtractedData,
//   setFinalMappingRequried,
//   setFinalCsvMappingRequried,
//   setFinalJsonMappingRequried,
//   setFinalXmlMappingRequried,
//   finalMappingRequried,
//   outputFields,
//   outputCsvFields,
//   outputJsonFields,
//   outputXmlFields,
//   isToggledForHeader,
// }: {
//   //   fileExtractedData: { [key: string]: any };
//   setFinalMappingRequried: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   setFinalCsvMappingRequried: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   setFinalJsonMappingRequried: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   setFinalXmlMappingRequried: React.Dispatch<
//     React.SetStateAction<{ [key: string]: string }>
//   >;
//   finalMappingRequried: { [key: string]: string };
//   outputFields: string[];
//   outputCsvFields: string[];
//   outputJsonFields: string[];
//   outputXmlFields: string[];
//   isToggledForHeader: boolean;
// }) => {
//   const handleSelect =
//     (key: string, type: string) => (selectedOption: { value: string }) => {
//       const mappedValue = selectedOption.value ? selectedOption.value : " ";
//       if (type === "common") {
//         setFinalMappingRequried((prev) => ({
//           ...prev,
//           [key]: mappedValue,
//         }));
//         console.log([key], mappedValue);
//       } else if (type === "csv") {
//         setFinalCsvMappingRequried((prev) => ({
//           ...prev,
//           [key]: mappedValue,
//         }));
//         console.log([key], mappedValue);
//       } else if (type === "json") {
//         setFinalJsonMappingRequried((prev) => ({
//           ...prev,
//           [key]: mappedValue,
//         }));
//       } else if (type === "xml") {
//         setFinalXmlMappingRequried((prev) => ({
//           ...prev,
//           [key]: mappedValue,
//         }));
//       }
//     };

//   //   const dropdownOptions = Array.isArray(fileExtractedData)
//   //     ? fileExtractedData.map((item: string) => ({
//   //         label: item,
//   //         value: item,
//   //       }))
//   //     : [];

//   return (
//     <div>
//       <h2 className="text-2xl text-center font-semibold mb-4 p-4">
//         Select the most applicable fields from the dropdown, to correspond the
//         below fields requested
//       </h2>
//       <div className="flex justify-between items-center mt-8">
//         <div className="w-full">
//           {/* {Object.keys(val).map((key) => ( */}
//           {isToggledForHeader ? (
//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-16">
//               {label.map((key) => (
//                 <div key={key.value}>
//                   <label>{key.label}</label>
//                   <Dropdown
//                     options={outputFields}
//                     onChange={handleSelect(key.value, "common")}
//                     //   value={finalMappingRequried[key] || val[key as keyof Val]}
//                     value={finalMappingRequried[key.value] || ""}
//                     placeholder={`Select`}
//                   />
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <>
//               {outputCsvFields.length !== 0 && (
//                 <>
//                   <h2 className="text-blue-600 font-semibold mt-4 my-4">
//                     Setup for CSV
//                   </h2>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {label.map((key) => (
//                       <div key={key.value}>
//                         <label>{key.label}</label>
//                         <Dropdown
//                           options={outputCsvFields}
//                           onChange={handleSelect(key.value, "csv")}
//                           value={finalMappingRequried[key.value] || ""}
//                           placeholder={`Select`}
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               )}

//               {outputJsonFields.length !== 0 && (
//                 <>
//                   <h2 className="text-blue-600 font-semibold mt-4 my-4">
//                     Setup for JSON
//                   </h2>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {label.map((key) => (
//                       <div key={key.value}>
//                         <label>{key.label}</label>
//                         <Dropdown
//                           options={outputJsonFields}
//                           onChange={handleSelect(key.value, "json")}
//                           value={finalMappingRequried[key.value] || ""}
//                           placeholder={`Select`}
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               )}

//               {outputXmlFields.length !== 0 && (
//                 <>
//                   <h2 className="text-blue-600 font-semibold mt-4 my-4">
//                     Setup for XML
//                   </h2>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {label.map((key) => (
//                       <div key={key.value}>
//                         <label>{key.label}</label>
//                         <Dropdown
//                           options={outputXmlFields}
//                           onChange={handleSelect(key.value, "xml")}
//                           value={finalMappingRequried[key.value] || ""}
//                           placeholder={`Select`}
//                         />
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               )}
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FinalConiguration;

import Select, { SingleValue } from "react-select";
import "react-dropdown/style.css";

const label = [
  { label: "Invoice No", value: "HFINVOICE_NO" },
  { label: "Invoice Date", value: "HFINVOICE_DATE" },
  { label: "Due Date", value: "HFDUEDATE" },
  { label: "Total Amount", value: "HFTOTAL_AMOUNT" },
  { label: "Total Gst", value: "HFTAX_AMOUNT" },
  { label: "Payable To", value: "HFVENDOR_NAME" },
  { label: "Invoice Currency", value: "HFTRADE_CURRENCY" },
  { label: "Buyer Name", value: "HFBUYER_NAME" },
  { label: "Commodity Data", value: "FCOMMODITY_DATA" },
  { label: "Taxes Table", value: "FTAX_DATA" },
  { label: "Charges Table", value: "FCHARGES_DATA" },
];

const FinalConiguration = ({
  setFinalMappingRequried,
  setFinalCsvMappingRequried,
  setFinalJsonMappingRequried,
  setFinalXmlMappingRequried,
  finalMappingRequried,
  outputFields,
  outputCsvFields,
  outputJsonFields,
  outputXmlFields,
  isToggledForHeader,
}: {
  setFinalMappingRequried: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setFinalCsvMappingRequried: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setFinalJsonMappingRequried: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setFinalXmlMappingRequried: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  finalMappingRequried: { [key: string]: string };
  outputFields: string[];
  outputCsvFields: string[];
  outputJsonFields: string[];
  outputXmlFields: string[];
  isToggledForHeader: boolean;
}) => {
  const handleSelect =
    (key: string, type: string) => (selectedOption: SingleValue<{ label: string; value: string }>) => {
      const mappedValue = selectedOption ? selectedOption.value : " "; // handle null case for no selection
      if (type === "common") {
        setFinalMappingRequried((prev) => ({
          ...prev,
          [key]: mappedValue,
        }));
        console.log([key], mappedValue);
      } else if (type === "csv") {
        setFinalCsvMappingRequried((prev) => ({
          ...prev,
          [key]: mappedValue,
        }));
        console.log([key], mappedValue);
      } else if (type === "json") {
        setFinalJsonMappingRequried((prev) => ({
          ...prev,
          [key]: mappedValue,
        }));
      } else if (type === "xml") {
        setFinalXmlMappingRequried((prev) => ({
          ...prev,
          [key]: mappedValue,
        }));
      }
    };

  // Convert the fields to an array of objects suitable for react-select
  const toSelectOptions = (fields: string[]) =>
    fields.map((field) => ({ label: field, value: field }));

  return (
    <div>
      <h2 className="text-2xl text-center font-semibold mb-4 p-4">
        Select the most applicable fields from the dropdown, to correspond the
        below fields requested
      </h2>
      <div className="flex justify-between items-center mt-8">
        <div className="w-full">
          {isToggledForHeader ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-16">
              {label.map((key) => (
                <div key={key.value}>
                  <label>{key.label}</label>
                  <Select
                    options={toSelectOptions(outputFields)}
                    onChange={handleSelect(key.value, "common")}
                    value={finalMappingRequried[key.value] ? { label: finalMappingRequried[key.value], value: finalMappingRequried[key.value] } : null}
                    placeholder={`Select`}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              {outputCsvFields.length !== 0 && (
                <>
                  <h2 className="text-blue-600 font-semibold mt-4 my-4">
                    Setup for CSV
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {label.map((key) => (
                      <div key={key.value}>
                        <label>{key.label}</label>
                        <Select
                          options={toSelectOptions(outputCsvFields)}
                          onChange={handleSelect(key.value, "csv")}
                          value={finalMappingRequried[key.value] ? { label: finalMappingRequried[key.value], value: finalMappingRequried[key.value] } : null}
                          placeholder={`Select`}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {outputJsonFields.length !== 0 && (
                <>
                  <h2 className="text-blue-600 font-semibold mt-4 my-4">
                    Setup for JSON
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {label.map((key) => (
                      <div key={key.value}>
                        <label>{key.label}</label>
                        <Select
                          options={toSelectOptions(outputJsonFields)}
                          onChange={handleSelect(key.value, "json")}
                          value={finalMappingRequried[key.value] ? { label: finalMappingRequried[key.value], value: finalMappingRequried[key.value] } : null}
                          placeholder={`Select`}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {outputXmlFields.length !== 0 && (
                <>
                  <h2 className="text-blue-600 font-semibold mt-4 my-4">
                    Setup for XML
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {label.map((key) => (
                      <div key={key.value}>
                        <label>{key.label}</label>
                        <Select
                          options={toSelectOptions(outputXmlFields)}
                          onChange={handleSelect(key.value, "xml")}
                          value={finalMappingRequried[key.value] ? { label: finalMappingRequried[key.value], value: finalMappingRequried[key.value] } : null}
                          placeholder={`Select`}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinalConiguration;
