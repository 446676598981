// import { useForm, Controller, SubmitHandler } from "react-hook-form";
// import Header from "../../dashboard/Header";
// import RadioGroupComponent from "../../../components/RadioGroupComponent";
// import { useEffect, useState } from "react";
// import Select from "react-select";
// import { MultiValue } from "react-select";
// import axios from "axios";
// import {ADD_CONFIG_API,GET_FORMAT_NAME} from "../../../utils/api-details/ApiList";
// import CsvForm from "./CsvForm";
// import JsonForm from "./JsonForm";
// import XmlForm from "./XmlForm";

// interface FormData {
//   json_type: string;
//   json_url: string;
//   json_method?: string;
//   json_requestType?: string;
//   json_requestBody?: string;
//   json_pathParam?: string;
//   json_file?: FileList;

//   csv_type: string;
//   csv_url: string;
//   csv_method?: string;
//   csv_requestType?: string;
//   csv_requestBody?: string;
//   csv_pathParam?: string;
//   csv_file?: FileList;

//   xml_type: string;
//   xml_url: string;
//   xml_method?: string;
//   xml_requestType?: string;
//   xml_requestBody?: string;
//   xml_pathParam?: string;
//   xml_file?: FileList;

//   format: string;
//   selectedFormats: string[];
// }

// interface FormatItem {
//   INVOICE_FORMAT_ID: number;
//   FORMAT_NAME: string;
//   status: string;
// }

// const ConfigApi = () => {
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const orgID = sessionStorage.getItem("orgId");
//   const userID = sessionStorage.getItem("userId");
//   const [selectedLevel, setSelectedLevel] = useState("format_name");
//   const [selectedFormat, setSelectedFormat] = useState<string[]>([]);
//   const [formatList, setFormatList] = useState<FormatItem[]>([]);
//   const get_format_name = GET_FORMAT_NAME;
//   const add_config_api = ADD_CONFIG_API;

//   const levels = [
//     { Label: "Invoice Format Level", value: "format_name" },
//     { Label: "Organization Level", value: "org" },
//   ];

//   const supportedFormats_options = ["csv", "json", "xml"];

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     control,
//   } = useForm<FormData>();

//   useEffect(() => {
//     getFormatDetails();
//   }, []);

//   const getFormatDetails = () => {
//     const payload = {
//       org_id: parseInt(orgID ?? ""),
//       user_id: parseInt(userID ?? ""),
//     };
//     axios
//       .post(`${apiUrl + get_format_name}`, payload, {
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//       })
//       .then((response) => {
//         const results = response?.data?.data?.data?.results;
//         const processedResults = results.map((item: FormatItem) => ({
//           INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
//           FORMAT_NAME: item.FORMAT_NAME,
//           status: item.status,
//         }));

//         console.log("processedResults", processedResults);
//         setFormatList(processedResults);
//       })
//       .catch((error) => {
//         console.error("Error fetching data from API:", error);
//       });
//   };

//   const handleLevelChange = (value: string) => {
//     setSelectedLevel(value);
//   };

//   const handleSelectedOptions = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const value = event.target.value;
//     setSelectedFormat((prev) =>
//       prev.includes(value)
//         ? prev.filter((option) => option !== value)
//         : [...prev, value]
//     );
//   };

//   const onSubmit: SubmitHandler<FormData> = (data) => {
//     console.log(data);
//     console.log("selectedLevel", selectedLevel);
//     console.log("setSelectedOptions", selectedFormat);
//     console.log(
//       "Selected Formats (react-select multi-select):",
//       data.selectedFormats
//     );

//     // Access the file through data.file
//     // if (data.file && data.file.length > 0) {
//     //   console.log("File uploaded: ", data.file[0].name);
//     // }

//     const payload = {
//       supported_format: data.selectedFormats,
//       csv: selectedFormat?.includes("csv")
//         ? {
//             type: data.csv_type,
//             apiURL: data.csv_url,
//             apiMethod: data.csv_method,
//             requestType: data.csv_requestType,
//             requestBody: data.csv_requestBody,
//             attachmentName: data.csv_file ? data.csv_file[0]?.name : "",
//           }
//         : null,
//       json: selectedFormat.includes("json")
//         ? {
//             type: data.json_type,
//             apiURL: data.json_url,
//             apiMethod: data.json_method,
//             requestType: data.json_requestType,
//             requestBody: data.json_requestBody,
//             attachmentName: data.json_file ? data.json_file[0]?.name : "",
//           }
//         : null,

//       xml: selectedFormat.includes("xml")
//         ? {
//             type: data.xml_type,
//             apiURL: data.xml_url,
//             apiMethod: data.xml_method,
//             requestType: data.xml_requestType,
//             requestBody: data.xml_requestBody,
//             attachmentName: data.xml_file ? data.xml_file[0]?.name : "",
//           }
//         : null,
//     };

//     axios
//       .post(`${apiUrl + add_config_api}`, payload, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         params: {
//           org_id: orgID,
//           user_id: userID,
//         },
//       })
//       .then((response) => {
//         const results = response?.data?.data?.data?.results;
//         const processedResults = results.map((item: FormatItem) => ({
//           INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
//           FORMAT_NAME: item.FORMAT_NAME,
//           status: item.status,
//         }));

//         console.log("processedResults", processedResults);
//         setFormatList(processedResults);
//       })
//       .catch((error) => {
//         console.error("Error fetching data from API:", error);
//       });
//   };

//   // BaseForm logic inside ConfigApi

//   const BaseForm = () => {
//     return (
//       <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
//         <div className="flex flex-col space-y-4">
//           {selectedFormat.includes("csv") && (
//             <CsvForm register={register} errors={errors} />
//           )}

//           {selectedFormat.includes("json") && (
//             <JsonForm register={register} errors={errors} />
//           )}

//           {selectedFormat.includes("xml") && (
//             <XmlForm register={register} errors={errors} />
//           )}

//           {/* Submit Button */}
//           <div className="my-4">
//             <button
//               type="submit"
//               className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600"
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       </form>
//     );
//   };

//   return (
//     <div>
//       <Header />
//       <div className="px-4 pb-10">
//         <div className="w-full p-8 border-2 border-gray-300 rounded-lg bg-grey-50 flex flex-col pb-10">
//           <h3 className="text-lg font-semibold font-Inter mb-4 mt-4">
//             Select the level for setting up Api config:
//           </h3>
//           <RadioGroupComponent
//             selectedValue={selectedLevel}
//             handleValueChange={handleLevelChange}
//             levels={levels}
//           />
//           <div>
//             <h2 className="text-lg font-medium mb-4 mt-8">
//               Select the formats
//             </h2>
//             <div className="mb-4">
//               <Controller
//                 name="selectedFormats"
//                 control={control}
//                 render={({ field }) => {
//                   const sortedOptions = formatList
//                     ?.sort((a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID)
//                     .map((option) => ({
//                       label: option.FORMAT_NAME,
//                       value: option.FORMAT_NAME,
//                       isDisabled: option.status === "IN ACTIVE",
//                     }));

//                   const selectAllOption = {
//                     label: "Select All",
//                     value: "selectAll",
//                   };
//                   const allSelected =
//                     field.value?.length === sortedOptions.length;
//                   const updatedValue = allSelected
//                     ? ["Selected All"]
//                     : field.value;

//                   return (
//                     <Select
//                       {...field}
//                       isMulti
//                       options={[selectAllOption, ...sortedOptions]}
//                       value={updatedValue?.map((val: string) => ({
//                         label: val === "Selected All" ? "Selected All" : val,
//                         value: val,
//                       }))}
//                       onChange={(
//                         selected: MultiValue<{ label: string; value: string }>
//                       ) => {
//                         const values = selected?.some(
//                           (item) => item.value === "selectAll"
//                         )
//                           ? sortedOptions.map((option) => option.value)
//                           : selected?.map((item) => item.value) || [];
//                         field.onChange(values);
//                       }}
//                       placeholder="Select formats"
//                     />
//                   );
//                 }}
//               />
//             </div>
//           </div>

//           {/* Checkboxes for output formats */}
//           <div>
//             <h2 className="text-lg font-medium mb-4 mt-6">
//               Select the supported output formats
//             </h2>
//             <div className="flex flex-wrap mb-4">
//               {supportedFormats_options.map((option) => (
//                 <div key={option} className="text-sm font-medium p-2">
//                   <label>
//                     <input
//                       type="checkbox"
//                       value={option}
//                       checked={selectedFormat.includes(option)}
//                       onChange={handleSelectedOptions}
//                       className="mr-2"
//                     />
//                     {option.toUpperCase()}
//                   </label>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* BaseForm Component for Rendering the Dynamic Forms */}
//           <BaseForm />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ConfigApi;


import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Header from "../../dashboard/Header";
import RadioGroupComponent from "../../../components/RadioGroupComponent";
import { useEffect, useState } from "react";
import Select from "react-select";
import { MultiValue } from "react-select";
import axios from "axios";
import { ADD_CONFIG_API, GET_FORMAT_NAME } from "../../../utils/api-details/ApiList";
import CsvForm from "./CsvForm";
import JsonForm from "./JsonForm";
import XmlForm from "./XmlForm";
import { IconArrowBigLeft, IconArrowBigLeftFilled } from "@tabler/icons-react";

const SUPPORTED_FORMATS = ["csv", "json", "xml"];

interface FormData {
  json_type: string;
  json_url: string;
  json_method?: string;
  json_requestType?: string;
  json_requestBody?: string;
  json_pathParam?: string;
  json_file?: FileList;

  csv_type: string;
  csv_url: string;
  csv_method?: string;
  csv_requestType?: string;
  csv_requestBody?: string;
  csv_pathParam?: string;
  csv_file?: FileList;

  xml_type: string;
  xml_url: string;
  xml_method?: string;
  xml_requestType?: string;
  xml_requestBody?: string;
  xml_pathParam?: string;
  xml_file?: FileList;

  format: string;
  selectedFormats: string[];
}

interface FormatItem {
  INVOICE_FORMAT_ID: number;
  FORMAT_NAME: string;
  status: string;
}

const ConfigApi = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedLevel, setSelectedLevel] = useState("format_name");
  const [selectedFormat, setSelectedFormat] = useState<string[]>([]);
  const [formatList, setFormatList] = useState<FormatItem[]>([]);
  
  const get_format_name = GET_FORMAT_NAME;
  const add_config_api = ADD_CONFIG_API;

  const levels = [
    { Label: "Invoice Format Level", value: "format_name" },
    { Label: "Organization Level", value: "org" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  useEffect(() => {
    fetchFormatDetails();
  }, []);

  const fetchFormatDetails = async () => {
    try {
      const response = await axios.post(`${apiUrl + get_format_name}`, {
        org_id: parseInt(orgID ?? ""),
        user_id: parseInt(userID ?? ""),
      }, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      const results = response?.data?.data?.data?.results;
      setFormatList(results?.map((item: FormatItem) => ({
        INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
        FORMAT_NAME: item.FORMAT_NAME,
        status: item.status,
      })));
    } catch (error) {
      console.error("Error fetching format details:", error);
    }
  };

  const handleLevelChange = (value: string) => setSelectedLevel(value);

  const handleSelectedOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedFormat((prev) =>
      prev.includes(value) ? prev.filter((option) => option !== value) : [...prev, value]
    );
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const payload = {
      supported_format: data.selectedFormats,
      csv: selectedFormat.includes("csv") ? buildPayload("csv", data) : null,
      json: selectedFormat.includes("json") ? buildPayload("json", data) : null,
      xml: selectedFormat.includes("xml") ? buildPayload("xml", data) : null,
    };

    axios
      .post(`${apiUrl + add_config_api}`, payload, {
        headers: { "Content-Type": "application/json" },
        params: { org_id: orgID, user_id: userID },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        setFormatList(results?.map((item: FormatItem) => ({
          INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
          FORMAT_NAME: item.FORMAT_NAME,
          status: item.status,
        })));
      })
      .catch((error) => console.error("Error submitting config:", error));
  };

  const buildPayload = (type: "csv" | "json" | "xml", data: FormData) => {
    const file = data[`${type}_file` as keyof FormData]?.[0];
  
    return {
      type: data[`${type}_type` as keyof FormData],
      apiURL: data[`${type}_url` as keyof FormData],
      apiMethod: data[`${type}_method` as keyof FormData],
      requestType: data[`${type}_requestType` as keyof FormData],
      requestBody: data[`${type}_requestBody` as keyof FormData],
      attachmentName: file instanceof File ? file.name : "",
    };
  };

  const BaseForm = () => (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        {selectedFormat.includes("csv") && <CsvForm register={register} errors={errors} />}
        {selectedFormat.includes("json") && <JsonForm register={register} errors={errors} />}
        {selectedFormat.includes("xml") && <XmlForm register={register} errors={errors} />}
        
        <div className="mt-4">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-all"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );

  return (
    <div>
      <Header />
      <div className="px-4 pb-10">
        <div className="w-full p-8 border-2 border-gray-300 rounded-lg bg-gray-50 flex flex-col pb-10">
          
          {/* Back Button */}
          <div className="pb-4">
            {/* <button
              type="button"
              onClick={() => window.history.back()}
              className="bg-gray-500 text-white p-3 rounded-lg hover:bg-gray-600 transition-all"
            >
              Back
            </button> */}
            {/* <IconArrowBigLeft onClick={() => window.history.back()} stroke={2} color="#3b82f6" className="h-8 w-8"/> */}
            <IconArrowBigLeftFilled stroke={1} onClick={() => window.history.back()} color="#3b82f6" className="h-8 w-8"/>
          </div>

          {/* Select the Level */}
          <h3 className="text-lg font-semibold mb-4">Select the level for setting up Api config:</h3>
          <RadioGroupComponent
            selectedValue={selectedLevel}
            handleValueChange={handleLevelChange}
            levels={levels}
          />

          {/* Select Formats */}
          <div>
            <h2 className="text-lg font-medium mb-4 mt-8">Select the formats</h2>
            <Controller
              name="selectedFormats"
              control={control}
              render={({ field }) => {
                const sortedOptions = formatList
                  ?.sort((a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID)
                  ?.map((option) => ({
                    label: option.FORMAT_NAME,
                    value: option.FORMAT_NAME,
                    isDisabled: option.status === "IN ACTIVE",
                  }));
                const selectAllOption = { label: "Select All", value: "selectAll" };
                const allSelected = field.value?.length === sortedOptions.length;

                return (
                  <Select
                    {...field}
                    isMulti
                    options={[selectAllOption, ...sortedOptions]}
                    value={allSelected ? [{ label: "Selected All", value: "selectAll" }] : field.value?.map(val => ({ label: val, value: val }))}
                    onChange={(selected: MultiValue<{ label: string; value: string }>) => {
                      const values = selected?.some((item) => item.value === "selectAll")
                        ? sortedOptions?.map((option) => option.value)
                        : selected?.map((item) => item.value) || [];
                      field.onChange(values);
                    }}
                    placeholder="Select formats"
                  />
                );
              }}
            />
          </div>

          {/* Select Output Formats */}
          <div className="mt-6">
            <h2 className="text-lg font-medium mb-4">Select the supported output formats</h2>
            <div className="flex flex-wrap mb-4">
              {SUPPORTED_FORMATS?.map((option) => (
                <div key={option} className="text-sm font-medium p-2">
                  <label>
                    <input
                      type="checkbox"
                      value={option}
                      checked={selectedFormat.includes(option)}
                      onChange={handleSelectedOptions}
                      className="mr-2"
                    />
                    {option.toUpperCase()}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Base Form */}
          <BaseForm />
        </div>
      </div>
    </div>
  );
};

export default ConfigApi;
