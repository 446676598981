import { useEffect, useState } from "react";
import Switch from "react-switch";

const FormatName = ({
  setFormatName,
  formatName,
  setHasChanges,
  supportedOutput,
  setSupportedOutput,
  isToggledForHeader,
  setIsToggledForHeader,
  setIsErrorInStep
}: {
  setFormatName: (name: string) => void;
  formatName: string;
  setHasChanges: (value: boolean) => void;
  supportedOutput: string[];
  setSupportedOutput: (output: string[]) => void;
  isToggledForHeader: boolean;
  setIsToggledForHeader: (value: boolean) => void;
  setIsErrorInStep: (value: boolean) => void;

}) => {
  const options = ["csv", "json", "xml"];
  const [selectedOptions, setSelectedOptions] = useState<string[]>(supportedOutput);
  const [error, setError] = useState('');

  useEffect(() => {
    if (formatName !== "") {
      setHasChanges(true);
    }
  });

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormatName(event.target.value);
  //   setHasChanges(true);
  // };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setFormatName(value);

    // Regular expression to match only alphanumeric characters and special characters
    const regex = /^[A-Za-z0-9_@./$*#&+-]*$/;
    // const regex = /^[a-zA-Z0-9]+$/;

    if (!regex.test(value)) {
      setError('Format name must not contain spaces ex:Formatname_1.');
      setIsErrorInStep(true)
    } else {
      setError('');
      setIsErrorInStep(false)

    }
  };

  useEffect(() => {
    setSelectedOptions(supportedOutput);
    if (supportedOutput.length > 0) {
      setHasChanges(true);
    }
  }, [setHasChanges, supportedOutput]);

  const handleSelectedOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
    setHasChanges(true);
  };

  const handleToggle = (checked: boolean) => {
    setIsToggledForHeader(checked);
    setHasChanges(true);
  };

  useEffect(() => {
    setSupportedOutput(selectedOptions);
  }, [selectedOptions, setSupportedOutput]);

  return (
    <div className="flex flex-col items-center justify-center mt-8">
      {/* <div className="flex justify-between items-center">
        <label className="flex-none pl-4 font-medium">Name of the Format</label>
        <div className="flex-1 pr-4 ml-4">
          <input
            type="text"
            value={formatName}
            onChange={handleChange}
            placeholder="Enter format name"
            className="border-2 border-gray-300 p-1 w-96 mt-6"
          />
          <p className="mt-1 text-gray-500">
            Hint: vendor name can be used for uniqueness
          </p>
        </div>
      </div> */}

  <div className="flex justify-between items-center">
      <label className="flex-none pl-4 font-medium">Name of the Format</label>
      <div className="flex-1 pr-4 ml-4">
        <input
          type="text"
          value={formatName}
          onChange={handleChange}
          placeholder="Enter format name"
          className="border-2 border-gray-300 p-1 w-96 mt-6"
        />
        {error && <p className="mt-1 text-red-500">{error}</p>}
        <p className="mt-1 text-gray-500">
          Hint: vendor name can be used for uniqueness
        </p>
      </div>
    </div>

      
      <div>
        <h2 className="text-lg font-medium text-center mb-4 mt-8">
          Select the supported output formats
          {/* Select the supported output formats for {formatName} */}
        </h2>
        <div className="flex flex-wrap justify-center mb-4">
          {options.map((option) => (
            <div key={option} className="text-sm font-medium p-2">
              <label>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={handleSelectedOptions}
                  className="mr-2"
                />
                {option.toUpperCase()}
              </label>
            </div>
          ))}
        </div>
        {/* {isError && (selectedOptions.length === 0 || formatName === "") && <p className="text-red-500">Please select a format name and supported output to proceed.</p>} */}
        {selectedOptions.length > 1 && (
          <div>
            <label>
              <Switch
                onChange={handleToggle}
                checked={isToggledForHeader}
                offColor="#888"
                onColor="#0096FF"
                height={20}
                width={40}
                uncheckedIcon={false}
                checkedIcon={false}
                // className='mt-2'
              />
            </label>
            <span className="text-sm font-semibold ml-2 ">
              All formats will have the same header
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormatName;
