import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import pdf from "./../../assets/pdf-icon.png";
import axios from "axios";
import { GET_FORMAT_NAME } from "../../utils/api-details/ApiList";
import { useToast } from "../../components/Toast";
import { IconX } from "@tabler/icons-react";

const UploadedFileDetails = ({
  isFileDetailsModalOpen,
  closeFileDetailsModal,
  fileName,
  handleConvertToJson,
  handleConvertToCsv,
  handleConvertToXml,
  selectedOption,
}: {
  isFileDetailsModalOpen: boolean;
  closeFileDetailsModal: () => void;
  // fileName: string;
  fileName: string[];

  handleConvertToJson: () => void;
  handleConvertToCsv: () => void;
  handleConvertToXml: () => void;
  selectedOption: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [supportedOutputTypes, setSupportedOutputTypes] = useState<string[]>(
    []
  );
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const get_format_name = GET_FORMAT_NAME;
  const { showToast } = useToast();

  useEffect(() => {
    console.log("fileName", fileName);
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        if (results) {
          const types = new Set<string>();
          results.forEach((item: any) => {
            if (item.FORMAT_NAME === selectedOption) {
              const supportedTypes: string[] = JSON.parse(
                item.SUPPORTED_OUTPUT_TYPES
              );
              supportedTypes.forEach((type) => {
                if (typeof type === "string") {
                  types.add(type);
                }
              });
            }
          });
          setSupportedOutputTypes(Array.from(types));
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Something went wrong", "error");
      });
    // }, [apiUrl, orgID, selectedOption, supportedOutputTypes, userID]);
  }, []);

  return (
    <Modal
      isOpen={isFileDetailsModalOpen}
      onClose={closeFileDetailsModal}
      title="Upload PDF"
      showCancelButton={false}
      size="w-5/12"
    >
      <div>
        {/* <div className="relative flex items-center justify-between p-2 w-full h-24 border-2 border-gray-200 rounded-lg bg-sky-50">
          <div className="flex items-center">
            <img src={pdf} alt="pdf-icon" className="h-10 w-10 " />
            <div className="ml-4 flex flex-col">
              <p className="text-gray-700">{fileName[0]}</p>
            </div>
          </div>
        </div> */}
        <div
          className="max-h-56"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
            whiteSpace: "nowrap",
          }}
        >
          <div
            className="p-4"
            // className={`p-2 grid gap-2 ${
            //   fileName.length > 4 ? "grid-cols-2" : "grid-cols-1"
            // }`}
          >
            {fileName.map((file, index) => (
              <div
                key={index}
                className="relative flex items-center justify-between p-2 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
              >
                {/* Tooltip visibility on hover */}
                {/* <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded-md shadow-md">
          {file}
        </div> */}

                {/* Display file name */}
                {/* <img src={pdf} alt="pdf-icon" className="h-10 w-10 " />

        <div className="flex items-center">
          <p className="text-gray-700 truncate">{file}</p>
        </div> */}

                <div className="flex items-center">
                  <img src={pdf} alt="pdf-icon" className="h-10 w-10 " />
                  <div className="ml-4 flex flex-col">
                    <p className="text-gray-700">{file}</p>
                  </div>
                </div>
                {/* Remove file icon */}
         {/* <IconX
          // onClick={() => handleRemoveFile(file)} // Implement this function to remove the file
          stroke={1}
          className="cursor-pointer text-gray-700 hover:text-red-500 transition-colors"
        /> */}
              </div>
            ))}
          </div>
        </div>

        <h2 className="font-medium text-center mt-8">Convert To</h2>
        {supportedOutputTypes.length === 0 ? 'Loading...' :
        <div className="flex flex-col items-center mt-4">
          <div className="flex space-x-4">
            {supportedOutputTypes.includes("json") && (
              <button
                type="button"
                onClick={handleConvertToJson}
                className="px-4 py-2 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              >
                JSON
              </button>
            )}
            {supportedOutputTypes.includes("csv") && (
              <button
                type="button"
                onClick={handleConvertToCsv}
                className="px-4 py-2 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              >
                CSV
              </button>
            )}
            {supportedOutputTypes.includes("xml") && (
              <button
                type="button"
                onClick={handleConvertToXml}
                className="px-4 py-2 w-36 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              >
                XML
              </button>
            )}
          </div>
        </div>
}
      </div>
    </Modal>
  );
};

export default UploadedFileDetails;
