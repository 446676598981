import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import EditJson from "./EditJson";
import { downloadJSON, downloadPDF } from "../../../components/Download";
import axios from "axios";
import parse from "html-react-parser";
import { IconTriangleInvertedFilled } from "@tabler/icons-react";
import CalculatorPage from "../../../components/Calculator";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useToast } from "../../../components/Toast";
import {
  HTML_TO_PDF,
  INVOICE_VERSION_BY_INVOICE_NAME,
  JSON_TO_HTML,
  VIEW_JSON,
} from "../../../utils/api-details/ApiList";

export const ViewJson = ({
  isViewModalOpen,
  onClose,
  uploadedFileName,
  format_name,
  latestVersion,
  invoiceType,
  setIsViewModalOpen
}: {
  isViewModalOpen?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
  format_name?: string;
  latestVersion?: number;
  invoiceType?: string;
  setIsViewModalOpen?:(value:boolean) => void;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [isViewHtml, setIsViewHtml] = useState(false);
  const [htmlData, setHtmlData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [versionList, setVersionList] = useState([]);
  const { showToast } = useToast();
  const view_json = VIEW_JSON;
  const invoice_version_by_invoice_name = INVOICE_VERSION_BY_INVOICE_NAME;
  const json_to_html = JSON_TO_HTML;
  const html_to_pdf = HTML_TO_PDF;

  useEffect(() => {
    // setSelectedOption("");
    console.log("IN view json isViewModalOpen",isViewModalOpen)
    if (isViewModalOpen) {
      setIsModalOpen(true);
      previewFile(invoiceType ? invoiceType : "appended");
      getVersionList("");
    } else {
      setIsModalOpen(false);
      setSelectedOption("");
    }
    if (uploadedFileName) {
      const fileName = uploadedFileName?.split(".")[0];
      setFileName(fileName);
    }
  
  }, [apiUrl, fileName, isViewModalOpen, uploadedFileName]);

  useEffect(() =>{
    if (selectedVersion) {
      previewFile(
        selectedOption === "original_json" || invoiceType === "original"
          ? "original"
          : "appended"
      );
      getVersionList(
        selectedOption === "original_json" ? "original_json" : "appended_json"
      );
    }
  }, [selectedVersion]);


  const previewFile = (type: string) => {
    if (fileName !== "") {
      axios
        .get(`${apiUrl + view_json}/${fileName + ".json"}`, {
          params: {
            format_name: format_name,
            version:
              selectedVersion || latestVersion
                ? selectedVersion || latestVersion
                : 0,
            type: type,
            orgid: orgID,
            user_id: userID,
          },
        })
        .then((response) => {
          setJsonData(response.data);
        })
        .catch(function (error) {
          console.log(error);
          const errorMsg = error?.response?.data?.detail;
          setJsonData(errorMsg);
          showToast(errorMsg ? errorMsg :"Something went wrong!", "error");
        });
    }
  };

  const getVersionList = async (optionSelected: string) => {
    const payload = {
      invoice_name: fileName + ".json",
      format_name: format_name,
      org_id: orgID,
      user_id: userID,
    };
    if (fileName !== "") {
      await axios
        .post(`${apiUrl + invoice_version_by_invoice_name}`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log("response.data", response?.data?.data?.results);
          if (response?.data?.data?.results) {
            let allVersions = [];
            if (
              optionSelected === "original_json" ||
              invoiceType === "original"
            ) {
              const originalData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "ORIGINAL" ||
                  item.FINVOICE_TYPE === "original"
              );
              allVersions =
                originalData.length > 0
                  ? originalData[0].all_version.split(",")
                  : [];
            } else if (
              optionSelected === "appended_json" ||
              optionSelected === "" ||
              invoiceType === "appended"
            ) {
              const appendedData = response.data.data.results.filter(
                (item: { FINVOICE_TYPE: string }) =>
                  item.FINVOICE_TYPE === "APPENDED" ||
                  item.FINVOICE_TYPE === "appended"
              );
              allVersions =
                appendedData.length > 0
                  ? appendedData[0].all_version.split(",")
                  : [];
            }
            setVersionList(allVersions);
          }
        })
        .catch(function (error) {
          console.log(error);
          showToast(
            "Something went wrong while getting invoice version!",
            "error"
          );
        });
    }
  };

  const handleEditJson = () => {
    setIsEditModalOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (parsedData: any) => {
    setJsonData(parsedData);
    setIsEditModalOpen(false);
    getVersionList(selectedOption ?? "");
  };

  const handleDownloadJSON = async () => {
    await downloadJSON(
      apiUrl,
      uploadedFileName,
      selectedOption,
      format_name,
      selectedVersion,
      latestVersion,
      invoiceType
    )
      .then(() => {
        console.log("Json downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading Json:", error);
        showToast("Something went wrong!", "error");
      });
  };

  const handleDownloadPDF = () => {
    downloadPDF(apiUrl, uploadedFileName, format_name)
      .then(() => {
        console.log("PDF downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
        const errorMsg = error?.response?.statusText
          ? "File " + error?.response?.statusText
          : "Something went wrong!";
        showToast(errorMsg, "error");
      });
  };

  const handleViewHtml = async () => {
    setIsViewHtml(true);
    const fileName = uploadedFileName?.split(".")[0] + ".json";
    await axios
      .get(`${apiUrl + json_to_html}/${fileName}`, {
        params: {
          format_name: format_name,
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        setHtmlData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setHtmlData(error?.response?.data?.detail);
        const errorMsg = error?.response?.data?.detail;
        showToast(errorMsg ? errorMsg : "Something went wrong!", "error");
      });

    await axios
      .get(`${apiUrl + html_to_pdf}/${fileName}`, {
        params: {
          format_name: format_name,
          org_id: orgID,
          user_id: userID,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        showToast("Something went wrong!", "error");
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    getVersionList(value);
    switch (value) {
      case "html_view":
        handleViewHtml();
        setIsViewHtml(true);
        break;
      case "original_json":
        previewFile("original");
        setIsViewHtml(false);
        break;
      case "appended_json":
        previewFile("appended");
        setIsViewHtml(false);
        break;
      default:
        setIsViewHtml(false);
        setSelectedOption("");
        break;
    }
  };

  const handleSelectedVersion = (selected: { value: string }) => {
    const value = selected.value;
    setSelectedVersion(value);
  };

  const handleCloseViewJson = () =>{
    console.log("handleCloseViewJson")
       setIsModalOpen(false);
          setIsViewHtml(false);
          if (setIsViewModalOpen) {
            setIsViewModalOpen(false);  
          }
          if (onClose) onClose();
  }
  
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        // onClose={() => {
        //   setIsModalOpen(false);
        //   setIsViewHtml(false);
        //   if (onClose) onClose();
        // }}
        onClose={handleCloseViewJson}
        title={fileName + (isViewHtml ? " - HTML" : " - JSON")}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col ">
          <div className="mt-auto flex justify-end space-x-4">
            <div className="border border-gray-300 rounded-lg relative">
              <select
                className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                id="dropdown"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="">View Option</option>
                {/* {(selectedOption !== "original_json" || invoiceType !== "original")&& ( */}
                {(selectedOption === "appended_json" || invoiceType === "appended" || (selectedOption === "" && invoiceType === undefined)) && (
                  <option value="original_json">Original JSON</option>
                )}
                {(isViewHtml || selectedOption === "original_json" || invoiceType === "original") && (
                  <option value="appended_json">Appended Json</option>
                )}
                {!isViewHtml && <option value="html_view">HTML View</option>}
              </select>
              <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2 text-gray-700">
                {/* <IconChevronDown stroke={1} color="#3b82f6" /> */}
                <IconTriangleInvertedFilled className="w-4 h-2 text-gray-400 " />
              </div>
            </div>
            {!isViewHtml && (
              <Dropdown
                options={versionList}
                onChange={handleSelectedVersion}
                value={selectedVersion}
                placeholder="Select Version"
                controlClassName="w-full px-4 py-2 !border-1 !border-blue-700 !rounded-lg !text-blue-600 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                menuClassName="border-2 border-blue-500 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
                arrowClassName="mt-1"
              />
            )}
            {!isViewHtml && (
              <>
                <button
                  type="button"
                  className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                  onClick={handleEditJson}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                  onClick={handleDownloadJSON}
                >
                  Download
                </button>
              </>
            )}
            {isViewHtml && (
              <button
                type="button"
                className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                onClick={handleDownloadPDF}
              >
                Download
              </button>
            )}
            <CalculatorPage />
          </div>
        </div>
        {!isViewHtml && (
          <div
            className="h-96  items-center justify-between mt-12 overflow-y-scroll "
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
            <div>
              <h1 className="font-bold text-sm">
                {selectedOption === "original_json" ||
                invoiceType === "original"
                  ? "Original Json"
                  : "Appended Json"}
              </h1>
              <pre className="p-4 rounded-lg overflow-x-auto whitespace-pre-wrap w-max">
                {jsonData ? JSON.stringify(jsonData, null, 2) : "Loading..."}
              </pre>
            </div>
          </div>
        )}

        {isViewHtml && (
          <div
            className="h-96  items-center justify-between mt-12 overflow-y-scroll "
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
            <div>{htmlData ? parse(htmlData) : "Loading..."}</div>
          </div>
        )}
      </Modal>

      <EditJson
        isEditModalOpen={isEditModalOpen}
        onClose={handleClosePopup}
        onCancel={handleCancel}
        handleSave={handleSave}
        fileName={fileName}
        selectedOption={selectedOption}
        format_name={format_name}
        orgID={orgID ?? ""}
        userID={userID ?? ""}
        selectedVersion={selectedVersion}
      />
    </div>
  );
};

export default ViewJson;
