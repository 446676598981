// import { useState, useEffect } from "react";
// import Modal from "../../components/Modal";
// import pdf from "./../../assets/pdf-icon.png";


// export const FailedFilesWarning = (
//   {
//     failedFile

// //   onClose,
//  }: {
//   failedFile?: string[];
// //   onClose: () => void;
//  }
// ) => {

//   useEffect(() =>{
//     console.log("failedFile",failedFile)
//   })
//   return (
//     <div>
//       <div className="p-6">
//         <Modal
//           isOpen={true}
//           onClose={() => {
//             // setIsModalOpen(false);
//             // if (onClose) onClose();
//           }}
//           title=" "
//           showCancelButton={false}
//           size="w-5/12"
//         >
//           <div className=" items-center justify-between my-4">
           
//               <p className="text-center text-lg">
//                 File is successfully converted into XML
//               </p>
//               {/* {failedFile?.map((file, index) => (
//                     <div
//                       key={index}
//                       className="flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
//                     >
//                       <div className="flex items-center space-x-4 flex-grow">
//                         <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
//                         <div className="ml-4 flex flex-col">
//                           <p className="text-gray-700">{file}</p>
//                         </div>
//                       </div>
                  
//                     </div>
//                   ))} */}
//           </div>
//         </Modal>
//       </div>

//     </div>
//   );
// };

// export default FailedFilesWarning;


import { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import pdf from "./../../assets/pdf-icon.png";

export const FailedFilesWarning = ({
  failedFile,
}: {
  failedFile?: { error: string; filename: string }[]; 
}) => {
  const [isFailedFilesOpen, setIsFailedFilesOpen] = useState<boolean>(true);

  useEffect(() => {
    setIsFailedFilesOpen(true)
    console.log("failedFile", failedFile);
  }, [failedFile]);

  const handleClose = () =>{
    setIsFailedFilesOpen(false)
  }
  return (
    <div>
      <div>
        <Modal
          isOpen={isFailedFilesOpen}
          onClose={handleClose}
          title="Failed Files"
          showCancelButton={false}
          size="w-5/12"
        >
          <div className="p-4 items-center justify-between max-h-96 overflow-auto"
           style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}>
            <p className="text-lg">
              The following {failedFile?.length === 1 ?'file' :'files'} have failed the upload:
            </p>
            {/* Display the failed files list */}
            <div className="mt-4">
              {failedFile?.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group min-w-96 overflow-auto"
                  style={{
                    scrollbarWidth: "thin",
                    scrollbarColor: "#3b82f6 #f1f1f1",
                  }}
                >
                  <div className="flex items-center space-x-4 flex-grow">
                    <img src={pdf} alt="pdf-icon" className="h-10 w-10" />
                    <div className="ml-4 flex flex-col">
                      <p className="text-gray-700">{file.filename}</p>
                      <p className="text-red-500 text-sm">{file.error}</p> 
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FailedFilesWarning;
