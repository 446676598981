import { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import axios from "axios";
import { IconCheck } from "@tabler/icons-react";
import FormatName from "../steps/FormatName";
import AddFields from "../steps/addFields/AddFields";
import Mapping from "../steps/mapping/Mapping";
import InvoiceList from "../steps/formatList/InvoiceList";
import { useNavigate } from "react-router-dom";
import { useToast } from "./../../../components/Toast";
import ConfirmationLookupConfig from "../steps/configureLookup/ConfirmLookUpConfig";
import ConfigureLookup from "../steps/configureLookup/ConfigureLookup";
import ConfirmConfigure from "../steps/finalConfirmation/ConfirmConfigure";
import {
  ADD_FIELDS,
  GET_FORMAT_NAME,
  GET_OUTPUT_TYPES,
  RESOLVE_NAME_DICTIONARY,
  UPDATE_LOOKUP_DETAILS,
  UPDATE_MAPPING_FIELDS,
  VIEW_FIELDS,
  VIEW_FORMAT_HEADERS,
  VIEW_LOOKUP_DICTIONARY,
  VIEW_MAPPING_FIELDS,
  VIEW_SETUP_FILE,
} from "../../../utils/api-details/ApiList";

// interface LookupDetails {
//   url?: string;
//   method?: string;
//   username?: string;
//   password?: string;
//   requestBody?: {};
//   pathParam?: {};
// }

interface BuyerSupplierData {
  supplierName?: string;
  buyerName?: string;
}

// Define steps for the stepper
const steps = [
  "Format List",
  "Format Name",
  "Add Fields",
  "Mapping Field",
  "Configure Lookup",
  "Confirm Configure",
];

// Map steps to components
const stepComponents = [
  InvoiceList,
  FormatName,
  AddFields,
  Mapping,
  ConfigureLookup,
  ConfirmConfigure,
];

const EditStepper = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentStep, setCurrentStep] = useState(0);
  const [formatName, setFormatName] = useState("");
  const [supportedOutput, setSupportedOutput] = useState<string[]>([]);
  const [isToggledForHeader, setIsToggledForHeader] = useState(false);
  const [fieldsList, setFieldsList] = useState<string[]>([]);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [jsonFields, setJsonFields] = useState<string[]>([]);
  const [xmlFields, setXmlFields] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const [jsonFiles, setJsonFiles] = useState<File[]>([]);
  const [xmlFiles, setXmlFiles] = useState<File[]>([]);
  const [mapping, setMapping] = useState<{ [key: string]: string }>({});
  const [csvMapping, setCsvMapping] = useState<{ [key: string]: string }>({});
  const [jsonMapping, setJsonMapping] = useState<{ [key: string]: string }>({});
  const [xmlMapping, setXmlMapping] = useState<{ [key: string]: string }>({});
  const [outputFields, setOutputFields] = useState<any[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [showNull, setShowNull] = useState("");
  const [dictionaryMappingRequired, setDictionaryMappingRequired] =
    useState("");
  const [value, setValue] = useState<string | null>(null);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  // const [isError, setIsError] = useState(false);
  const [outputCsvFields, setOutputCsvFields] = useState<any[]>([]);
  const [outputJsonFields, setOutputJsonFields] = useState<any[]>([]);
  const [outputXmlFields, setOutputXmlFields] = useState<any[]>([]);
  const [selectedFormat, setSelectedFormat] = useState<any[]>([]);
  const [fileExtractedData, setFileExtractedData] = useState({});
  const [buyerSupplier, setBuyerSupplier] = useState<BuyerSupplierData[]>([]);
  
  // const [buyerSupplier, setBuyerSupplier] = useState<BuyerSupplierData>({});
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const [isConfigureLookup, setIsConfigureLookup] = useState(false);
  // const [lookupDetails, setLookupDetails] = useState({});
  // const [buyerLookupDetails, setBuyerLookupDetails] = useState<LookupDetails>({});

  const navigate = useNavigate();
  const { showToast } = useToast();
  const [activeTab, setActiveTab] = useState("buyer");
  const [selectedLevel, setSelectedLevel] = useState("format_name");
  const [selectedSetup, setSelectedSetup] = useState("dictionary");
  const [dictionaryObject, setDictionaryObject] = useState("");
  const update_Lookup_Details = UPDATE_LOOKUP_DETAILS;
  const resolve_name_dic = RESOLVE_NAME_DICTIONARY;
  const get_format_name = GET_FORMAT_NAME;
  const get_output_types = GET_OUTPUT_TYPES;
  const add_fields = ADD_FIELDS;
  const view_fields = VIEW_FIELDS;
  const update_mapping_fields = UPDATE_MAPPING_FIELDS;
  const view_mapping_fields = VIEW_MAPPING_FIELDS;
  const view_format_headers = VIEW_FORMAT_HEADERS;
  const view_lookup_dict = VIEW_LOOKUP_DICTIONARY;
  const view_setup_file = VIEW_SETUP_FILE;
  const [isEdit , setIsEdit]= useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [isErrorInStep, setIsErrorInStep]  = useState(false);

  useEffect(() => {
    setIsEdit(true)
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        if (results) {
          setSelectedFormat([]);
          // const sortedResults = results?.sort((a: any, b: any) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID); 
          const sortedResults = results
          ?.filter((item: any) => item.status !== "IN ACTIVE") // Filter out items with status "IN ACTIVE"
          .sort((a: any, b: any) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID); 
         
         const formatNames = sortedResults?.map(
            (item: { FORMAT_NAME: string }) => item.FORMAT_NAME
          );
          setSelectedFormat(formatNames);
          // Get the formatId based on the selected format name
          const formatId = results
            ?.filter(
              (item: { FORMAT_NAME: string }) => item.FORMAT_NAME === formatName
            )
            .map(
              (item: { INVOICE_FORMAT_ID: number }) => item.INVOICE_FORMAT_ID
            );
          const id = formatId[0];
          // setSelectedFormatId(id);
          if (id) {
            getOutputTypes(id);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
        showToast("Something went wrong!", "error");
      });
  }, [apiUrl, formatName, orgID, userID]);

  // Fetch supported types based on the formatId
  const getOutputTypes = (formatId: number) => {
    const payload = {
      format_id: formatId,
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_output_types}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const result: [] = response?.data?.data?.data?.results;
        if (result.length <= 1) {
          const type =
            response?.data?.data?.data?.results[0].SUPPORTED_OUTPUT_TYPES;
          const supportedType = JSON.parse(type);
          setIsToggledForHeader(true);
          setSupportedOutput([]);
          setSupportedOutput(supportedType);
        } else {
          setIsToggledForHeader(false);
          const allSupportedTypes = result
            .map((item: { SUPPORTED_OUTPUT_TYPES: any }) =>
              JSON.parse(item.SUPPORTED_OUTPUT_TYPES)
            )
            .flat();
          setSupportedOutput([]);
          setSupportedOutput(allSupportedTypes);
        }
        viewFieldsAPI();
        viewMappingFieldsAPI();
        // getLookupDict();
        // getSetupFile();
      })
      .catch((error) => {
        console.error("Error fetching output types:", error);
      });
  };

  useEffect(() => {
    viewFieldsAPI();
    viewMappingFieldsAPI();
    // getLookupDict();
    // getSetupFile();
  }, [isToggledForHeader, supportedOutput]);

  const handleNext = async () => {
    const apiSteps = [2, 3]; // Steps requiring API calls
    if (apiSteps.includes(currentStep)) {
      await apiCallForStep(currentStep);
    }
    if (currentStep + 1 === 4) {
      setIsLookupModalOpen(true);
    }
    setCurrentStep(currentStep + 1);
    // setIsError(false);
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
    if (!isConfigureLookup) {
      if (currentStep - 1 === 4) {
        setCurrentStep(3);
      }
    }
  };

  const handleOk = () => {
    navigate("/dashboard");
    setIsEdit(false)
  };

  const CurrentStepComponent = stepComponents[currentStep];

  const addFieldsAPI = async () => {
    const formatType = supportedOutput.join(",");
    const fields = fieldsList.join(",");
    const formData = new FormData();
    // Append files to formData
    files.forEach((file) => {
      formData.append("file", file);
    });
    // Append fields
    formData.append("fields", fieldsList.length > 0 ? fields : "");
    formData.append("format_type", formatType);
    formData.append("format_name", formatName);
    formData.append("orgid", orgID ?? "");
    formData.append("user_id", userID ?? "");
    if (isToggledForHeader) {
      try {
        const response = await axios.post(`${apiUrl + add_fields}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Response from addFieldsAPI:", response);
        viewFieldsAPI();
      } catch (error) {
        console.error("Error in addFieldsAPI:", error);
      }
    } else {
      for (const type of supportedOutput) {
        const formatData = new FormData();
        switch (type) {
          case "json":
            jsonFiles.forEach((file) => {
              formatData.append("file", file);
            });
            const jsonFieldsString =
              jsonFields.length > 0 ? jsonFields.join(",") : "";
            formatData.append("fields", jsonFieldsString);
            break;
          case "csv":
            csvFiles.forEach((file) => {
              formatData.append("file", file);
            });
            const csvFieldsString =
              csvFields.length > 0 ? csvFields.join(",") : "";
            formatData.append("fields", csvFieldsString);
            break;
          case "xml":
            xmlFiles.forEach((file) => {
              formatData.append("file", file);
            });
            const xmlFieldsString =
              xmlFields.length > 0 ? xmlFields.join(",") : "";
            formatData.append("fields", xmlFieldsString);
            break;
          default:
            break;
        }
        formatData.append("format_type", type);
        formatData.append("format_name", formatName);
        formatData.append("orgid", orgID ?? "");
        formatData.append("user_id", userID ?? "");
        try {
          const response = await axios.post(
            `${apiUrl + add_fields}`,
            formatData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(`Response for ${type} format:`, response);
          viewFieldsAPI();
        } catch (error) {
          console.error(`Error in addFieldsAPI for ${type}:`, error);
        }
      }
    }
  };

  const viewFieldsAPI = async () => {
    setOutputCsvFields([]);
    setCsvFields([]);
    setOutputJsonFields([]);
    setJsonFields([]);
    setOutputXmlFields([]);
    setXmlFields([]);
    setFieldsList([]);
    setOutputFields([]);
    try {
      // Fetch data for all formatTypes
      for (const formatType of supportedOutput) {
        const response = await axios.get(`${apiUrl + view_fields}`, {
          params: {
            format_type: formatType,
            format_name: formatName,
            orgid: orgID,
            user_id: userID,
          },
        });
        // console.log("Response from viewFieldsAPI:", response);
        const fields = response.data.fields;
        if (isToggledForHeader) {
          setFieldsList(fields);
          setOutputFields(fields);
        }
        // Set specific fields based on formatType
        switch (formatType) {
          case "csv":
            setOutputCsvFields(fields);
            setCsvFields(fields);
            break;
          case "json":
            setOutputJsonFields(fields);
            setJsonFields(fields);
            break;
          case "xml":
            setOutputXmlFields(fields);
            setXmlFields(fields);
            break;
          default:
            console.warn(`Unknown formatType: ${formatType}`);
        }
      }
    } catch (error) {
      console.error("Error in viewFieldsAPI:", error);
    }
  };

  useEffect(() => {
    console.log("Updated CSV Fields:", outputCsvFields);
  }, [outputCsvFields]);

  useEffect(() => {
    console.log("Updated JSON Fields:", outputJsonFields);
  }, [outputJsonFields]);

  useEffect(() => {
    console.log("Updated XML Fields:", outputXmlFields);
  }, [outputXmlFields]);

  const mappingFieldsAPI = async () => {
    if (isToggledForHeader) {
      const updatedMapping = { ...mapping };
      outputFields.forEach((field) => {
        if (!(field in updatedMapping)) {
          updatedMapping[field] = "";
        }
      });
      // const formatType = supportedOutput.join(",");
      const payload = {
        data_dict: updatedMapping,
        data_setting_dict: {
          skip_null: showNull === "Yes" ? "True" : "False",
          alias: showNull === "Yes" ? value : "-",
          dictionary_mapping_required: dictionaryMappingRequired,
        },
      };
      for (const formatType of supportedOutput) {
        try {
          const response = await axios.patch(
            `${apiUrl + update_mapping_fields}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                format_type: formatType,
                format_name: formatName,
                orgid: orgID,
                user_id: userID,
              },
            }
          );
          console.log(`Response for ${formatType}:`, response.data);
        } catch (error) {
          console.error(`Error for ${formatType}:`, error);
        }
      }
    } else {
      const updatedCsvMapping = { ...csvMapping };
      outputCsvFields.forEach((field) => {
        if (!(field in updatedCsvMapping)) {
          updatedCsvMapping[field] = "";
        }
      });
      const updatedJsonMapping = { ...jsonMapping };
      outputJsonFields.forEach((field) => {
        if (!(field in updatedJsonMapping)) {
          updatedJsonMapping[field] = "";
        }
      });
      const updatedXmlMapping = { ...xmlMapping };
      outputXmlFields.forEach((field) => {
        if (!(field in updatedXmlMapping)) {
          updatedXmlMapping[field] = "";
        }
      });

      for (const formatType of supportedOutput) {
        let payload;
        switch (formatType.toLowerCase()) {
          case "csv":
            payload = {
              data_dict: updatedCsvMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          case "json":
            payload = {
              data_dict: updatedJsonMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          case "xml":
            payload = {
              data_dict: updatedXmlMapping,
              data_setting_dict: {
                skip_null: showNull === "Yes" ? "True" : "False",
                alias: showNull === "Yes" ? value : "-",
                dictionary_mapping_required: dictionaryMappingRequired,
              },
            };
            break;
          default:
            break;
        }
        try {
          const response = await axios.patch(
            `${apiUrl + update_mapping_fields}`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                format_type: formatType,
                format_name: formatName,
                orgid: orgID,
                user_id: userID,
              },
            }
          );
          console.log(
            `Response from mappingFieldsAPI for ${formatType}:`,
            response
          );
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
        }
      }
    }
  };

  const viewMappingFieldsAPI = async () => {
    if (formatName !== "") {
      getFormatHeaders();
    }
    if (isToggledForHeader) {
      const formatType = supportedOutput[0];
      axios
        .get(`${apiUrl + view_mapping_fields}`, {
          params: {
            format_type: formatType,
            format_name: formatName,
            user_id: parseInt(userID ?? ""),
            org_id: parseInt(orgID ?? ""),
          },
        })
        .then((response) => {
          console.log("Response from mappingFieldsAPI:", response?.data);
          const mappingValue = response?.data?.data_dict;
          setMapping(mappingValue);
          const skip_null = response?.data?.data_setting_dict?.skip_null;
          setValue(response?.data?.data_setting_dict?.alias);
          setShowNull(skip_null === "True" ? "Yes" : "No");
          setDictionaryMappingRequired(
            response?.data?.data_setting_dict?.dictionary_mapping_required
          );
        })
        .catch((error) => {
          console.error("Error in mappingFieldsAPI:", error);
          const errorMsg = error?.response?.data?.detail;
          showToast(errorMsg ? errorMsg : "Something went wrong", "error");
        });
    } else {
      for (const formatType of supportedOutput) {
        try {
          const response = await axios.get(`${apiUrl + view_mapping_fields}`, {
            params: {
              format_type: formatType,
              format_name: formatName,
              org_id: parseInt(orgID ?? ""),
              user_id: parseInt(userID ?? ""),
            },
          });
          console.log(
            `Response from mappingFieldsAPI for ${formatType}:`,
            response?.data
          );
          const mappingValue = response?.data?.data_dict;
          if (formatType === "csv") {
            setCsvMapping(mappingValue);
          }
          if (formatType === "json") {
            setJsonMapping(mappingValue);
          }
          if (formatType === "xml") {
            setXmlMapping(mappingValue);
          }
          const skip_null = response?.data?.data_setting_dict?.skip_null;
          setValue(response?.data?.data_setting_dict?.alias);
          setShowNull(skip_null === "True" ? "Yes" : "No");
          setDictionaryMappingRequired(
            response?.data?.data_setting_dict?.dictionary_mapping_required
          );
        } catch (error) {
          console.error(`Error in mappingFieldsAPI for ${formatType}:`, error);
        }
      }
    }
  };

  const getFormatHeaders = async () => {
    axios
      .get(`${apiUrl + view_format_headers}`, {
        params: {
          org_id: orgID,
          user_id: userID,
          format_name: formatName,
        },
      })
      .then((response) => {
        const headers = response?.data?.headers?.headers || [];
        setFileExtractedData(headers);
      })
      .catch((error) => {
        console.error("Error fetching output types:", error);
        showToast(error?.response?.data?.detail, "error");
      });
  };

  // Call API for the respective step
  const apiCallForStep = async (stepIndex: number) => {
    switch (stepIndex) {
      case 2:
        return addFieldsAPI();
      case 3:
        return mappingFieldsAPI();
      // case 4:
      //   return handleLookupDetails();
      default:
        throw new Error("No API defined for this step.");
    }
  };

  const handleCloseLookup = () => {
    setIsLookupModalOpen(false);
  };

  // const handleLookupDetails = () => {
  //   console.log("data", buyerLookupDetails);
  //   console.log("dic", dictionaryObject);
  //   const formatType = supportedOutput.join(",");
  //   // const dicPayload = dictionaryObject;
  //   axios
  //     .post(`${apiUrl + resolve_name_dic}`, dictionaryObject, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       params: {
  //         org_id: orgID,
  //         format_name: formatName,
  //         output_format_type: formatType,
  //         type: activeTab,
  //         save_level: selectedLevel,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("response", response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       showToast("Something went wrong", "error");
  //     });

  //   const payload = {
  //     field:
  //       activeTab === "buyer"
  //         ? buyerSupplier?.buyerName
  //         : buyerSupplier?.supplierName,
  //     isLookupEnabled: isConfigureLookup,
  //     lookupType: selectedSetup === "Api" ? "API" : "Dictionary",
  //     ...(selectedSetup === "Api" && {
  //       apiSetup: {
  //         url: buyerLookupDetails?.url,
  //         method: buyerLookupDetails?.method,
  //         requestBody: buyerLookupDetails?.requestBody,
  //         request_parameters: {
  //           org_id: orgID,
  //           user_id: userID,
  //         },
  //         pathParam: buyerLookupDetails?.pathParam,
  //         credentials: {
  //           username: buyerLookupDetails?.username,
  //           password: buyerLookupDetails?.password,
  //         },
  //         returnType: "JSON",
  //       },
  //     }),
  //   };
  //   axios
  //     .patch(`${apiUrl + update_Lookup_Details}`, payload, {
  //       params: {
  //         format_type: formatType,
  //         format_name: formatName,
  //         file_prefix: activeTab,
  //         org_id: orgID,
  //         user_id: userID,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("response", response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       showToast("Something went wrong", "error");
  //     });
  // };

  // const getLookupDict = () => {
  //   const formatType = supportedOutput.join(",");
  //   if (formatType !== "" && formatName !== "") {
  //     axios
  //       .get(`${apiUrl + view_lookup_dict}`, {
  //         params: {
  //           org_id: orgID,
  //           format_name: formatName,
  //           output_format_types: formatType,
  //         },
  //       })
  //       .then((response) => {})
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // };

  // const getSetupFile = () => {
  //   const formatType = supportedOutput.join(",");
  //   if (formatType !== "" && formatName !== "") {
  //     axios
  //       .get(`${apiUrl + view_setup_file}`, {
  //         params: {
  //           format_types: formatType,
  //           format_name: formatName,
  //           org_id: orgID,
  //           user_id: userID,
  //         },
  //       })
  //       .then((response) => {})
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // };
  return (
    <div className="w-full flex flex-col items-center pb-10 ">
      <div className="w-full px-12">
        <div className="p-0 ">
          <Stepper activeStep={currentStep}>
            {steps.map((step, index) => {
              const isActive = index === currentStep;
              const isCompleted = index < currentStep;
              return (
                <Step key={index} label={step}>
                  <div
                    className={`
                    ${isCompleted ? "bg-blue-500 text-white" : ""}${
                      isActive
                        ? "bg-blue-200  border-2 border-blue-500 text-black"
                        : ""
                    }${
                      !isActive && !isCompleted
                        ? "bg-gray-300 text-gray-600"
                        : ""
                    }
                    w-8 h-8 rounded-full flex justify-center items-center
                    text-sm font-semibold
                  `}
                  >
                    {isCompleted ? (
                      <IconCheck stroke={2} color="white" className="text-xl" />
                    ) : (
                      <span className="text-base">{index + 1}</span>
                    )}
                  </div>
                </Step>
              );
            })}
          </Stepper>
        </div>
        {/* <div className="h-96 p-4 border-2 border-gray-300 rounded-lg bg-sky-50 "> */}
        <div
          className={`min-h-96 max-h-[800vh]  p-4 border-2 border-gray-300 rounded-lg bg-sky-50`}
        >
          <CurrentStepComponent
            setFormatName={setFormatName}
            setSupportedOutput={setSupportedOutput}
            formatName={formatName}
            supportedOutput={supportedOutput}
            setIsToggledForHeader={setIsToggledForHeader}
            isToggledForHeader={isToggledForHeader}
            fieldsList={fieldsList}
            setFieldsList={setFieldsList}
            files={files}
            setFiles={setFiles}
            //   uploadInvoicefiles={uploadInvoiceFiles}
            //   setUploadInvoiceFiles={setUploadInvoiceFiles}
            setMapping={setMapping}
            mapping={mapping}
            outputFields={outputFields}
            setHasChanges={setHasChanges}
            showNull={showNull}
            setShowNull={setShowNull}
            value={value}
            setValue={setValue}
            setCsvFiles={setCsvFiles}
            csvFiles={csvFiles}
            setJsonFiles={setJsonFiles}
            jsonFiles={jsonFiles}
            setXmlFiles={setXmlFiles}
            xmlFiles={xmlFiles}
            csvFields={csvFields}
            setCsvFields={setCsvFields}
            jsonFields={jsonFields}
            setJsonFields={setJsonFields}
            xmlFields={xmlFields}
            setXmlFields={setXmlFields}
            //   isError={isError}
            outputCsvFields={outputCsvFields}
            outputJsonFields={outputJsonFields}
            outputXmlFields={outputXmlFields}
            csvMapping={csvMapping}
            setCsvMapping={setCsvMapping}
            jsonMapping={jsonMapping}
            setJsonMapping={setJsonMapping}
            xmlMapping={xmlMapping}
            setXmlMapping={setXmlMapping}
            // setSelectedFormat={setSelectedFormat}
            selectedFormat={selectedFormat}
            fileExtractedData={fileExtractedData}
            // setFileExtractedData={setFileExtractedData}
            // setIsDeactivated={setIsDeactivated}
            // isDeactivated={isDeactivated}
            buyerSupplier={buyerSupplier}
            // setBuyerSupplier={setBuyerSupplier}
            isCreate={false}
            isConfigureLookup={isConfigureLookup}
            // setBuyerLookupDetails={setBuyerLookupDetails}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            // setSelectedSetup={setSelectedSetup}
            // selectedSetup={selectedSetup}
            // setSelectedLevel={setSelectedLevel}
            // selectedLevel={selectedLevel}
            setDictionaryObject={setDictionaryObject}
            dictionaryObject={dictionaryObject}
            isEdit={isEdit}
            // errorMessage={errorMessage}
            setIsErrorInStep={setIsErrorInStep}

          />
        </div>
      </div>
      <div className="flex space-x-4 mt-6">
        <button
          onClick={handlePrev}
          disabled={currentStep === 0}
          className={`px-4 py-2 rounded ${
            currentStep === 0
              ? "bg-gray-300 text-gray-500"
              : "bg-blue-600 text-white"
          }`}
        >
          Previous
        </button>
        <button
          onClick={currentStep === steps.length - 1 ? handleOk : handleNext}
          disabled={currentStep === steps.length - 1 && !formatName}
          className={`px-4 py-2 rounded ${
            currentStep === steps.length - 1
              ? "bg-blue-600 text-white"
              : "bg-blue-600 text-white"
          }`}
        >
          {currentStep === steps.length - 1 ? "OK" : "Save & Continue"}
        </button>
      </div>

      {isLookupModalOpen && (
        <ConfirmationLookupConfig
          open={isLookupModalOpen}
          onClose={handleCloseLookup}
          setIsConfigureLookup={setIsConfigureLookup}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};

export default EditStepper;
