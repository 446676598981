import { IconCloudUpload, IconX } from "@tabler/icons-react";
import React, { useState, ChangeEvent, DragEvent, useEffect } from "react";
import ConfirmationPopup from "./ConfirmationPopup";
import Modal from "../../components/Modal";
import axios from "axios";
import WarningPopup from "../../components/WarningPopup";
import UploadingProcess from "./UploadingProcess";
import UploadedFileDetails from "./UploadedFileDetails";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useToast } from "../../components/Toast";
import {
  BATCH_PROCESS,
  GET_FORMAT_NAME,
  INVOICE_TO_CSV,
  INVOICE_TO_JSON,
  INVOICE_TO_XML,
  UPLOAD_INVOICE,
  BATCH_CONVERSION,
} from "../../utils/api-details/ApiList";
import Select from "react-select";
import { SingleValue } from "react-select";
import FailedFilesWarning from "./FailedFilesWarning";

interface FormatItem {
  INVOICE_FORMAT_ID: number;
  FORMAT_NAME: string;
  status: string;
}

interface errorFileds {
  error: string;
  filename: string;
}
const FileUpload = ({
  open,
  closeUploadFileModal,
  showModal,
}: {
  open: boolean;
  closeUploadFileModal: () => void;
  showModal: boolean;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlBulkUpload = process.env.REACT_APP_API_URL_Bulk;
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [fileName, setFileName] = useState<string[]>([]);
  const [isConvertToJson, setIsConvertToJson] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileDetailsModalOpen, setFileDetailsModalOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // const [formData, setFormData] = useState<FormData | null>(null);
  const [fileFormatErrorMessage, setFileFormatErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isConvertTocsv, setIsConvertTocsv] = useState(false);
  const [isConvertToXml, setIsConvertToXml] = useState(false);
  const orgID = sessionStorage.getItem("orgId");
  const userID = sessionStorage.getItem("userId");
  const userType = sessionStorage.getItem("user_type");
  const [formatList, setFormatList] = useState<FormatItem[]>([]);
  const [jsonFile, setJsonFile] = useState<string[]>([]);
  const { showToast } = useToast();
  const upload_invoice = UPLOAD_INVOICE;
  const get_format_name = GET_FORMAT_NAME;
  const invoice_to_json = INVOICE_TO_JSON;
  const invoice_to_csv = INVOICE_TO_CSV;
  const invoice_to_xml = INVOICE_TO_XML;
  const [uploadInvoiceFiles, setUploadInvoiceFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const batch_process = BATCH_PROCESS;
  const batch_conversion = BATCH_CONVERSION;
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [failedFile, setFailedFile] = useState<errorFileds[]>([]);

  useEffect(() => {
    const payload = {
      org_id: parseInt(orgID ?? ""),
      user_id: parseInt(userID ?? ""),
    };
    axios
      .post(`${apiUrl + get_format_name}`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const results = response?.data?.data?.data?.results;
        if (results) {
          // Sort results in descending order based on INVOICE_FORMAT_ID
          const sortedResults = (results as FormatItem[]).sort(
            (a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID
          );

          // Extract unique FORMAT_NAME values
          const uniqueFormatNames: string[] = [];
          sortedResults.forEach((item: { FORMAT_NAME: string }) => {
            if (!uniqueFormatNames.includes(item.FORMAT_NAME)) {
              uniqueFormatNames.push(item.FORMAT_NAME);
            }
          });

          // Set the format list with unique format names
          // setFormatList(uniqueFormatNames);

          // full list of sorted format
          const formattedResults = sortedResults.map((item: FormatItem) => ({
            INVOICE_FORMAT_ID: item.INVOICE_FORMAT_ID,
            FORMAT_NAME: item.FORMAT_NAME,
            status: item.status,
          }));

          setFormatList(formattedResults);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, [apiUrl, orgID, userID]);

  useEffect(() => {
    if (uploading) {
      const uploadInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(uploadInterval);
            setUploading(false);
            return 100;
          }
          return prev + 10;
        });
      }, 500);
    }
  }, [uploading]);

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (): void => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const acceptedFiles = Array.from(event.dataTransfer.files).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    setUploadInvoiceFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedFiles = Array.from(event.target.files || []).filter(
      (file: File) => ["application/pdf"].includes(file.type)
    );
    setUploadInvoiceFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleRemoveFile = (fileToRemove: File) => {
    setUploadInvoiceFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const handleFileUpload = (files: File[]): void => {
    console.log("files", files);

    // Check if all files are PDFs
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== "application/pdf") {
        showToast("Invalid file type. Please upload only PDF files", "error");
        return;
      }
    }

    // Replace spaces in filenames with underscores and notify the user
    const updatedFileNames = files.map((file) => {
      if (file.name.includes(" ")) {
        showToast("File name has space, replacing with _", "success");
      }
      return file.name.replace(/ /g, "_");
    });

    // Store updated filenames
    setFileName(updatedFileNames);
    setProgress(0);
    setUploading(true);
    openModal();
    openFileDetailsModal();

    // Prepare the form data for the API request
    const api = files.length === 1 ? upload_invoice : batch_process;
    // const url = files.length === 1 ? apiUrl : "http://192.168.0.25:8000/api_v1";
    const url = files.length === 1 ? apiUrl : apiUrlBulkUpload;

    const formData = new FormData();

    files.forEach((file) => {
      formData.append(files.length === 1 ? "file" : "files", file);
    });

    // Create an AbortController to handle cancellation
    const controller = new AbortController();
    setAbortController(controller);

    axios
      .post(`${url + api}`, formData, {
        params: {
          user_type: userType === "A" ? "admin" : "user",
          org_id: orgID,
          format_name: selectedOption,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal: controller.signal,
      })
      .then((response) => {
        if (files.length > 1) {
          // Multiple files upload
          const errorMessages = response?.data?.message.filter(
            (item: any) => item.error
          );
          console.log("errorMessages", errorMessages);
          setFailedFile(errorMessages);

          const filenames = response?.data?.message
            ?.filter((item: any) => !item.error)
            ?.map((item: any) => item.filename);

          console.log("filenames without errors", filenames);

          setJsonFile(filenames);
          setFileName(filenames);
        } else {
          // Single file upload
          setJsonFile([response?.data?.filename]);
          // const fileData = response?.data?.message[0]; // Only one file expected
          // if (fileData?.error) {
          //   setFailedFile([fileData]); // Store error for single file
          //   showToast("File has an error.", "error");
          //   console.log("Error for single file:", fileData.error);
          // } else {
          //   setJsonFile([fileData.filename]);
          //   setFileName([fileData.filename]);
          //   showToast("File uploaded successfully.", "success");
          // }
        }
        setIsFileUploaded(true); // Make sure to set this after success
      })
      .catch((error) => {
        setUploading(false);
        setFileName([]);
        console.log("catch");

        if (axios.isCancel(error)) {
          console.log("File upload canceled");
          showToast("File upload canceled", "error");
        } else {
          const errorMsg =
            error?.response?.data?.detail ||
            "An error occurred during the file upload";
          console.error("Error uploading files:", error);
          showToast(errorMsg, "error");
        }
      })
      .finally(() => {
        console.log("In finally block");
        setUploading(false);
        setProgress(100);
      });

    // Simulate upload progress
    const uploadInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadInterval);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleCancel = (): void => {
    if (abortController) {
      abortController.abort();
      setUploading(false);
      setProgress(0);
      // setFileName(null);
      setFileName([]);
    }
  };

  const handleClosePopup = () => {
    // setFileName(null);
    // setFileName([]);
    //setIsConvertToJson(false);
    // setIsConvertTocsv(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFileName([]);
  };

  const openFileDetailsModal = () => {
    setFileDetailsModalOpen(true);
  };

  const closeFileDetailsModal = () => {
    setFileDetailsModalOpen(false);
    setFileName([]);
    setFileFormatErrorMessage("");
    setUploadInvoiceFiles([]);
    setSelectedOption("");
  };

  const handleConvert = (
    format: string,
    endpoint: string,
    setConversionState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    // if (jsonFile !== undefined) {
    if (jsonFile.length !== 0) {
      console.log("jsonFile", jsonFile);
      console.log("jsonFile", jsonFile.length);

      if (jsonFile?.length === 1) {
        axios
          .get(`${apiUrl + endpoint}`, {
            params: {
              filename: jsonFile[0],
              format_name: selectedOption,
              user_id: userID,
              orgid: orgID,
              conversion_type: jsonFile.length === 1 ? "" : format,
            },
          })
          .then((response) => {
            console.log(response);
            setConversionState(true);
          })
          .catch((error) => {
            console.error("Error:", error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.detail
            ) {
              setFileFormatErrorMessage(error.response.data.detail);
              showToast(error.response.data.detail, "error");
            } else {
              showToast("Something went wrong", "error");
            }
            setConversionState(false);
          });
      } else {
        axios
          .post(`${apiUrlBulkUpload + batch_conversion}`, "", {
          params: {
              filenames: jsonFile?.join(","),
              org_id: parseInt(orgID ?? ""),
              user_id: parseInt(userID ?? ""),
              format_name: selectedOption,
              conversion_type: format,
            },
          })
          .then((response) => {
            console.log("Batch conversion response:", response);
            setConversionState(true);
          })
          .catch((error) => {
            console.error("Error during batch conversion:", error);
            // Check if error response contains a message and display it
            if (
              error.response &&
              error.response.data &&
              error.response.data.detail
            ) {
              const errorMsg = error.response.data.detail;
              setFileFormatErrorMessage(errorMsg);
              showToast(errorMsg, "error");
            } else {
              showToast(
                "Something went wrong during batch conversion",
                "error"
              );
            }
            // Set conversion state to false to indicate failure
            setConversionState(false);
          });
      }
    } else {
      showToast("Filename is missing", "error");
    }
  };

  // Usage for JSON, CSV, and XML conversions
  const handleConvertToJson = () =>
    handleConvert("json", invoice_to_json, setIsConvertToJson);
  const handleConvertToCsv = () =>
    handleConvert("csv", invoice_to_csv, setIsConvertTocsv);
  const handleConvertToXml = () =>
    handleConvert("xml", invoice_to_xml, setIsConvertToXml);

  const handleExtractClick = () => {
    handleFileUpload(uploadInvoiceFiles);
  };

  const handleButtonClick = () => {
    const fileInput = document.querySelector(
      "input[type='file']"
    ) as HTMLInputElement;
    fileInput.click();
  };

  const handleClearAll = () => {
    setUploadInvoiceFiles([]);
  };

  // const sortedOptions = formatList
  //   ?.sort((a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID)
  //   ?.map((option) => ({
  //     label: option.FORMAT_NAME,
  //     value: option.FORMAT_NAME,
  //     isDisabled: option.status === "IN ACTIVE",
  //   }));

  const sortedOptions = formatList
    ?.filter((option) => option.status !== "IN ACTIVE")
    .sort((a, b) => b.INVOICE_FORMAT_ID - a.INVOICE_FORMAT_ID)
    .map((option) => ({
      label: option.FORMAT_NAME,
      value: option.FORMAT_NAME,
    }));

  const handleSelectChange = (
    selected: SingleValue<{ label: string; value: string }>
  ) => {
    if (selected) {
      setSelectedOption(selected.value);
    } else {
      setSelectedOption("");
    }
  };

  return (
    <div>
      <div className="flex  items-center justify-center h-full w-full">
        {!uploading && fileName.length === 0 && (
          <>
            {showModal ? (
              <Modal
                isOpen={open}
                onClose={closeUploadFileModal}
                title="Upload PDF"
                showCancelButton={false}
                size="w-7/12"
              >
                <UploadContent />
              </Modal>
            ) : (
              <UploadContent />
            )}
          </>
        )}

        {uploading && (
          <UploadingProcess
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            progress={progress}
            handleCancel={handleCancel}
          />
        )}
        {fileName.length !== 0 &&
          !uploading &&
          !isConvertToJson &&
          !isConvertTocsv &&
          !isConvertToXml &&
          isFileUploaded && (
            <UploadedFileDetails
              isFileDetailsModalOpen={isFileDetailsModalOpen}
              closeFileDetailsModal={closeFileDetailsModal}
              fileName={fileName}
              handleConvertToJson={handleConvertToJson}
              handleConvertToCsv={handleConvertToCsv}
              handleConvertToXml={handleConvertToXml}
              selectedOption={selectedOption}
            />
          )}
      </div>

      <ConfirmationPopup
        isConvertToJson={isConvertToJson}
        isConvertTocsv={isConvertTocsv}
        isConvertToXml={isConvertToXml}
        onClose={handleClosePopup}
        uploadedFileName={fileName ? fileName : []}
        format_name={selectedOption}
        setFileName={setFileName}
        setIsConvertToJson={setIsConvertToJson}
        setIsConvertTocsv={setIsConvertTocsv}
        setIsConvertToXml={setIsConvertToXml}
        setUploadInvoiceFiles={setUploadInvoiceFiles}
        setSelectedOption={setSelectedOption}
      />

      {fileFormatErrorMessage && (
        <WarningPopup
          isOpen={open}
          onClose={closeFileDetailsModal}
          title=" "
          showCancelButton={true}
          size="w-2/5"
        >
          <div className="p-3 text-center">
            <span className=" text-lg">{fileFormatErrorMessage}</span>
          </div>
        </WarningPopup>
      )}

      {failedFile.length !== 0 && (
        <FailedFilesWarning failedFile={failedFile} />
      )}
    </div>
  );

  function UploadContent() {
    return (
      <div className="w-full">
        <div className="flex flex-col w-52 max-w-xs">
          <label className="font-bold mb-2 text-gray-700" htmlFor="dropdown">
            Payable To:
          </label>
          {/* Select Format */}
          <div>
            <Select
              isMulti={false}
              options={sortedOptions}
              value={
                selectedOption
                  ? { label: selectedOption, value: selectedOption }
                  : null
              }
              onChange={handleSelectChange}
              placeholder="Select format"
            />
          </div>
        </div>

        <h2 className="font-semibold text-xl text-blue-700 mt-4">
          UPLOAD FILE
        </h2>
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 p-4 h-60  rounded-lg text-center ${
            isDragOver
              ? "bg-blue-50 border-blue-500"
              : "bg-blue-50 border-gray-300"
          }`}
        >
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="hidden"
            multiple
          />
          <div className="flex flex-col justify-center items-center">
            <IconCloudUpload stroke={2} color="#3b82f6" className="h-14 w-14" />
            <p className="mt-2 text-gray-700">
              Choose a file or Drag & drop it here
            </p>
            <button
              onClick={handleButtonClick}
              className="mt-4 w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Browse Files
            </button>
          </div>
        </div>

        {/* File List */}
        <div
          className="mt-6 max-h-36"
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
            whiteSpace: "nowrap",
          }}
        >
          <div
            className={`p-2 grid gap-2 ${
              uploadInvoiceFiles?.length > 4 ? "grid-cols-2" : "grid-cols-1"
            }`}
          >
            {uploadInvoiceFiles?.map((file, index) => {
              const truncatedName =
                file?.name?.length > 30
                  ? `${file?.name?.slice(0, 30)}...`
                  : file?.name;
              return (
                <div
                  key={index}
                  className="relative flex items-center justify-between p-3 mb-2 border-2 border-gray-200 rounded-lg bg-blue-50 group"
                >
                  <div className="flex items-center">
                    <p className="text-gray-700 truncate">{truncatedName}</p>
                  </div>

                  {/* Custom Tooltip */}
                  {file?.name?.length > 30 && (
                    <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded-md shadow-md">
                      {file.name}
                    </div>
                  )}
                  <IconX
                    onClick={() => handleRemoveFile(file)}
                    stroke={1}
                    className="cursor-pointer text-gray-700 hover:text-red-500 transition-colors"
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* Extract Button */}
        {uploadInvoiceFiles.length >= 1 && (
          <div className="mt-4 flex justify-center space-x-4">
            <button
              onClick={handleExtractClick}
              className="w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              disabled={isLoading || uploadInvoiceFiles.length === 0}
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>

            <button
              onClick={handleClearAll}
              className="w-36 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              disabled={isLoading || uploadInvoiceFiles.length === 0}
            >
              Clear
            </button>
          </div>
        )}
      </div>
    );
  }
};

export default FileUpload;
